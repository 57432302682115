@import "~bootstrap/dist/css/bootstrap.min.css";

@font-face {
  font-family: "Wells Fargo Sans";
  src: local("WellsFargoSans"),
    url("./global/fonts/WellsFargoSans_Rg.otf") format("opentype"),
    url("./global/fonts/WellsFargoSans_W_Rg.woff") format("woff"),
    url("./global/fonts/WellsFargoSans_Rg.ttf") format("truetype");
}

@font-face {
  font-family: "Wells Fargo Sans Light";
  src: local("WellsFargoSansLight"),
    url("./global/fonts/WellsFargoSans_Lt.otf") format("opentype"),
    url("./global/fonts/WellsFargoSans_W_Lt.woff") format("woff"),
    url("./global/fonts/WellsFargoSans_Lt.ttf") format("truetype");
}

@font-face {
  font-family: "Wells Fargo Sans SemiBold";
  src: local("WellsFargoSansSemiBold"),
    url("./global/fonts/WellsFargoSans_SBd.otf") format("opentype"),
    url("./global/fonts/WellsFargoSans_W_SBd.woff") format("woff"),
    url("./global/fonts/WellsFargoSans_SBd.ttf") format("truetype");
}

@font-face {
  font-family: "Wells Fargo Serif";
  src: local("WellsFargoSerif"),
    url("./global/fonts/WellsFargoSerif_Rg.otf") format("opentype"),
    url("./global/fonts/WellsFargoSerif_W_Rg.woff") format("woff"),
    url("./global/fonts/WellsFargoSerif_A_Rg.ttf") format("truetype");
}

@font-face {
  font-family: "Wells Fargo Serif Light";
  src: local("WellsFargoSerifLight"),
    url("./global/fonts/WellsFargoSerif_Lt.otf") format("opentype"),
    url("./global/fonts/WellsFargoSerif_W_Lt.woff") format("woff"),
    url("./global/fonts/WellsFargoSerif_A_Lt.ttf") format("truetype");
}

html {
  position: relative;
  min-height: 100%;
  background-color: #fff;
  min-width: 375px !important;
}

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  min-height: 100%;
  font-size: 15px;
  font-family: "Wells Fargo Sans", Verdana, Geneva, sans-serif !important;
  color: #3b3331;
  overflow-x: hidden;
}

body {
  width: 100%;
  position: absolute;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  overflow: initial;
}

p {
  display: block;
  /*margin-block-start: 1em;*/
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

h1,
.h1 {
  margin: 10px 0;
  font-size: 40px;
}

h1 small,
.h1 small {
  font-size: 34px;
}

h2,
.h2 {
  font-size: 34px;
  margin: 10px 0;
}

h3,
.h3 {
  font-size: 24px;
  margin: 10px 0;
}

h4,
.h4 {
  /*font-size: 20px !important;*/
  /*margin: 6px 0 !important;*/
}

h5,
.h5 {
  font-size: 15px;
  margin: 3px 0;
}

h6,
.h6 {
  font-size: 16px;
  margin: 3px 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.card-header {
  margin: 0;
  /*font-family: WellsFargoSerif,Georgia,Times,"Times New Roman",serif;*/
  font-weight: 425 !important;
  line-height: 1.3em;
  font-size: 18px;
}

a,
a:visited,
a:link {
  cursor: pointer;
}

a.disabled,
a:disabled,
a.disabled:hover {
  font-style: italic;
  text-decoration: none !important;
  cursor: not-allowed;
}

.btn:hover,
button:hover {
  text-decoration: underline;
}

.btn.disabled,
.btn:disabled,
.btn[disabled] {
  pointer-events: none;
}

a,
button {
  transition: all 0.3s linear;
}

a:active,
a:focus,
a:hover,
button,
button:active,
button:focus,
embed,
input::-moz-focus-inner,
object {
  outline: 0;
}

a,
button,
input {
  -ms-touch-action: none !important;
}

.container-Accordion {
  width: 1475px !important;
  padding-left: 60px;
  /*padding-top: 60px;*/
}

.container-masthead {
  height: 60px;
  border-bottom: solid 4px #ffcd41;
}
.container-masthead.bg-bilt .logo img {
  height: 30px;
}

.container-masthead-small .logo img {
  padding: 19px 0;
  height: 20px;
}

.container-masthead-small .navbar-toggle {
  line-height: 56px;
}

.container-masthead-mobile .logo img {
  padding: 11px 0;
  height: 42px;
}
.sidebar .nav li a,
.sidebar .logo a {
  color: #fff;
  text-decoration: none;
}
.imgFooterLogo {
  width: 193.22px;
  height: 44px;
  padding-left: 0.96%;
  padding-top: 0.29%;
  padding-bottom: 0.29%;
}
.copyright-2020-wel {
  height: 0.62%;
  width: 20.55%;
  color: #787070;
  font-family: "Wells Fargo Sans";
  font-size: 10px;
  line-height: 13px;
  padding-left: 1.49%;
}

.container-masthead .logo {
  float: left;
  margin-top: 17px;
  height: 22px;
}

.container-masthead-small .logo img {
  padding: 19px 0;
}

.container-masthead-small .navbar-toggle {
  line-height: 56px;
}

.container-masthead-mobile .logo img {
  padding: 11px 0;
}

.container-masthead-mobile .navbar-toggle {
  line-height: 40px;
}

.container-masthead-mobile {
  /* height: 42px; */
  height: 40px;
  border-bottom: solid 4px #ffcd41;
}

.container-masthead-small {
  height: 60px;
  border-bottom: solid 4px #ffcd41;
}

.container-masthead-mobile .navbar-container,
.container-masthead-small .navbar-container {
  position: relative;
  z-index: 968;
}
/* master head - navigation */
.container-masthead .nav,
.container-slavehead .nav,
.container-masthead-mobile .nav,
.container-masthead-small .nav,
.container-mastfoot,
.container-mastfoot-mobile {
  font-size: 13px;
  float: right;
  background-color: #f4f0ed;
}

.container-mastfoot {
  padding: 32px;
  background-color: #f4f0ed;
}

.container-masthead .nav .nav-item {
  padding: 20px 0;
}

.container-masthead .nav a {
  line-height: 16px;
  padding-top: 0;
  padding-bottom: 0;
}

.container-masthead .nav .nav-link:disabled,
.container-masthead-mobile .nav .nav-link:disabled,
.container-masthead-small .nav .nav-link:disabled,
.container-masthead .nav .nav-link.disabled,
.container-masthead-mobile .nav .nav-link.disabled,
.container-masthead-small .nav .nav-link.disabled,
.container-masthead .nav .nav-link[disabled],
.container-masthead-mobile .nav .nav-link[disabled],
.container-masthead-small .nav .nav-link[disabled] {
  color: #f4f0ed;
  font-style: italic;
}

.container-slavehead .nav a,
.container-slavehead-mobile .nav a,
.container-masthead-mobile .nav a,
.container-masthead-small .nav a {
  color: #3b3331;
  margin: 0;
}

.container-masthead .nav .active,
.container-slavehead .nav .active,
.container-slavehead-mobile .nav .active,
.container-masthead-mobile .nav .active,
.container-masthead-small .nav .active {
  font-weight: bold;
  font-style: italic;
  text-decoration: underline;
}

.container-masthead .nav a:hover,
.container-masthead-mobile .nav a:hover,
.container-masthead-small .nav a:hover,
.container-slavehead-mobile .nav a:hover,
.container-mastfoot .list-inline a:hover,
.container-mastfoot-mobile a:hover {
  text-decoration: underline;
}

.container-masthead-mobile .nav .nav-item,
.container-masthead-small .nav .nav-item,
.container-slavehead-mobile .nav .nav-item {
  border-bottom: solid 1px #fff;
}

.footer-wrapper {
  margin-top: auto;
  border-top: solid 1px #b5adad;
  /* background-color: #f4f0ed !important; */
}

.bg-red .has-float-label .form-control {
  border-color: #fff !important;
}

.bg-red .has-float-label .form-control:placeholder-shown:not(:focus) + * {
  opacity: 1;
}

.bg-red .has-float-label .custom-select {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23ffffff' d='M2 3L0 1h4z'/%3E%3C/svg%3E");
}
.bg-red {
  background-color: #d71e28;
}

.bg-bilt {
  background-color: #1f1f1f;
}

.bg-dgray {
  background-color: #f4f0ed;
}

.sidebar .nav {
  font-size: 14px;
}

.sidebar .nav li {
  width: 100%;
}

.sidebar .nav li a:focus,
.open .sidebar .nav li a:focus {
  background-color: inherit;
}

.sidebar .nav ul {
  padding: 0;
  /*height: 0px;
        overflow: hidden;*/
}

.loaded .sidebar .nav ul {
  height: auto;
}

.sidebar .nav li.active ul {
  height: auto;
}

.sidebar .nav li a,
.sidebar .logo a {
  color: #fff;
  text-decoration: none;
}

.sidebar .nav li.open a {
  color: #fff;
}

.sidebar .nav li a:hover,
.sidebar .nav li.open > a,
.sidebar .nav li.open a:hover {
  color: #d9d9d6;
  background-color: #696b6e;
}

.sidebar .nav > li > a {
  padding: 15px 10px 15px 20px;
}

.sidebar .nav > li.active > a,
.sidebar .nav > li.active > a:hover {
  background-color: #ae1e23 !important;
  color: #ffffff !important;
}

.sidebar .nav > li.open > a {
  background-color: #54565a;
}

.sidebar .nav > li.open > a span.arrow {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sidebar .nav > li > a i {
  margin-right: 5px;
  font-size: 16px;
}

.sidebar .nav > li > a span.arrow {
  float: right;
  font-size: 20px;
  line-height: initial;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.sidebar .nav > li > a span.arrow:before {
  content: "\f105" !important;
}

.sidebar .nav > li > ul > li a {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 50px;
  background-color: #747c84;
}

.sidebar .nav > li > ul > li.active a {
  color: #ffffff;
}
.modal .nav-tabs .nav-link.active,
.modal .nav-tabs .nav-link.active:focus,
.modal .nav-tabs .nav-link.active:hover,
.modal .nav-tabs .nav-item.open .nav-link,
.modal .nav-tabs .nav-item.open .nav-link:focus,
.modal .nav-tabs .nav-item.open .nav-link:hover,
.search-page .nav-tabs .nav-link.active,
.search-page .nav-tabs .nav-link.active:focus,
.search-page .nav-tabs .nav-link.active:hover,
.search-page .nav-tabs .nav-item.open .nav-link,
.modalsearch-page .nav-tabs .nav-item.open .nav-link:focus,
.search-page .nav-tabs .nav-item.open .nav-link:hover {
  background-color: #44464a;
  border-color: #44464a #44464a transparent;
  color: #fff;
}
.modal .nav-tabs .nav-item,
.search-page .nav-tabs .nav-item {
  display: block !important;
  width: 100%;
  margin: 0;
}

.modal .nav-tabs .nav-item .nav-link:active,
.search-page .nav-tabs .nav-item .nav-link:active {
  margin: 0;
}

.container-masthead .nav .nav-item {
  padding: 20px 0;
}
.container-slavehead .nav .nav-item:first-child a.nav-link {
  margin-left: 0;
}
.container-masthead-mobile .nav .nav-item,
.container-masthead-small .nav .nav-item,
.container-slavehead-mobile .nav .nav-item {
  border-bottom: solid 1px #fff;
}
.header {
  background-color: #ae1e23;
  height: 70px;
  position: absolute;
  left: 230px;
  right: 0;
  -webkit-transition: left 0.3s ease;
  transition: left 0.3s ease;
  z-index: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  left: 0;
}

@media (max-width: 991px) {
  .header {
    left: 0;
  }
}

@media (max-width: 767px) {
  .header {
    left: 0;
    height: 50px;
  }
}

.header-fixed .header {
  position: fixed;
}

.header .header-block {
  margin-right: 0;
}

@media all and (-ms-high-contrast: none) and (max-width: 750px) {
  .d-flex {
    display: block !important;
  } /* IE10 */
  *::-ms-backdrop,
  .d-flex {
    display: block !important;
  } /* IE11 */
}

@media (max-width: 767px) {
  .header .header-block {
    padding: 5px;
  }
}

.header .header-block-buttons {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  white-space: nowrap;
}

.header .header-block-buttons .btn.header-btn {
  background-color: transparent;
  border: 1px solid #64798d;
  color: #64798d;
  margin: 0 5px;
}

.header .header-block-buttons .btn.header-btn:hover,
.header .header-block-buttons .btn.header-btn:focus {
  border: 1px solid #3a4651;
  color: #3a4651;
}

@media (max-width: 767px) {
  .header .header-block-buttons {
    display: none;
  }
}

.header .header-block-collapse {
  padding-right: 5px;
}

.header .header-block-collapse .collapse-btn {
  background: none;
  border: none;
  box-shadow: none;
  color: #fff;
  font-size: 24px;
  line-height: 40px;
  border-radius: 0;
  outline: none;
  padding: 0;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: initial;
}

.header .header-block-nav {
  margin-left: auto;
  /*white-space: nowrap;*/
}

.header .header-block-nav::after {
  content: "";
  display: table;
  clear: both;
}

.header .header-block-nav a {
  text-decoration: none;
}

.header .header-block-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  float: right !important;
}

.header .header-block-nav > ul {
  display: table;
}

.header .header-block-nav > ul > li {
  display: table-cell;
  position: relative;
}

.header .header-block-nav > ul > li:before {
  display: block;
  content: " ";
  width: 1px;
  height: 24px;
  top: 50%;
  margin-top: -12px;
  background-color: #8b9cb1;
  position: absolute;
  left: 0;
}

.header .header-block-nav > ul > li:first-child:before {
  display: none;
}

.header .header-block-nav > ul > li > a {
  padding: 0 15px;
  color: #fff;
}

.header .header-block-nav > ul > li > a:hover {
  color: #d9d9d6;
}

.header .header-block-nav .dropdown-menu {
  margin-top: 15px;
}

/* ADA helper classes */
.sr-only {
  position: absolute !important;
  left: -10000px !important;
  top: auto !important;
  width: 1px !important;
  height: 1px !important;
  overflow: hidden !important;
}

h1,
.h1 {
  padding: 10px 0;
  font-size: 30px;
}

/* .main-wrapper-sorry {
  margin-top: 55px;
} 

@media (min-width: 768px) {
  
  .main-wrapper-sorry {
    margin-top: 72px;
  }
}

@media (min-width: 992px) {
 
  .main-wrapper-sorry {
    margin-top: 72px;
  }
}
*/
/* Modal Open Hide Content */
.bg-hide .main-wrapper {
  visibility: hidden;
}

@media (min-width: 768px) {
  /* Guttering fix for specific wf-cards */
  .card {
    margin-bottom: 26px;
  }
}

.card {
  transition: all 0.2s linear;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
}

@media (min-width: 768px) {
  /* Guttering fix for specific wf-cards */
  .card {
    margin-bottom: 26px;
  }
}

.card-body {
  padding: 0.8rem 0.9rem 0.7rem 0.9rem;
  border-top: solid 1px rgba(0, 0, 0, 0.125);
}

.card-header {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  background-color: #eeeeee;
  border-bottom: 0;
}

.card-header .btn {
  line-height: normal;
  height: auto;
  font-size: 0.75rem;
  margin-right: 2px;
}

.card-header .btn .fa {
  font-size: 0.85rem;
}

.card-footer {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

.card .list-group-flush .list-group-item {
  padding: 0.25rem 0.75rem;
}

/* Responsive Shadows */
.shadow-0 {
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.shadow {
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2) !important;
  -moz-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2) !important;
  -webkit-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2) !important;
}

@media screen and (min-width: 576px) {
  .shadow-sm {
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2) !important;
    -moz-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2) !important;
    -webkit-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2) !important;
  }
}

@media screen and (min-width: 768px) {
  .shadow-md {
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2) !important;
    -moz-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2) !important;
    -webkit-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2) !important;
  }
}

@media screen and (min-width: 992px) {
  .shadow-lg {
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2) !important;
    -moz-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2) !important;
    -webkit-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2) !important;
  }
}

@media screen and (min-width: 1200px) {
  .shadow-xl {
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2) !important;
    -moz-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2) !important;
    -webkit-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2) !important;
  }
}

/* Responsive Edge Paddings  */

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}
.pr-5,
.px-5 {
  padding-right: 3rem !important;
}
.pl-6,
.px-6 {
  padding-left: 4rem !important;
}
.pr-6,
.px-6 {
  padding-right: 4rem !important;
}

.pl-7,
.px-7 {
  padding-left: 5rem !important;
}
.pr-7,
.px-7 {
  padding-right: 5rem !important;
}

@media screen and (min-width: 576px) {
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 4rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 4rem !important;
  }

  .pl-sm-7,
  .px-sm-7 {
    padding-left: 5rem !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 5rem !important;
  }
}

@media screen and (min-width: 768px) {
  .pl-md-6,
  .px-md-6 {
    padding-left: 4rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 4rem !important;
  }

  .pl-md-7,
  .px-md-7 {
    padding-left: 5rem !important;
  }

  .pr-md-7,
  .px-md-7 {
    padding-right: 5rem !important;
  }
}

@media screen and (min-width: 992px) {
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 4rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 4rem !important;
  }

  .pl-lg-7,
  .px-lg-7 {
    padding-left: 5rem !important;
  }

  .pr-lg-7,
  .px-lg-7 {
    padding-right: 5rem !important;
  }
}

@media screen and (min-width: 1200px) {
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 4rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 4rem !important;
  }

  .pl-xl-7,
  .px-xl-7 {
    padding-left: 5rem !important;
  }

  .pr-xl-7,
  .px-xl-7 {
    padding-right: 5rem !important;
  }
}

.btn.disabled,
.btn:disabled,
.btn[disabled] {
  pointer-events: none;
}

.btn.disabled,
.btn.disabled:link,
.btn.disabled:hover,
.btn.disabled:focus,
.btn.disabled:active,
.btn[disabled],
.btn[disabled]:hover,
.btn[disabled]:focus,
.btn[disabled]:active {
  background-color: #efa5a9;
  border-color: #efa5a9;
  opacity: 1;
  color: #fff;
}

@media screen and (-ms-high-contrast: none) {
  /* IE11 specific styles go here */
  .btn-sm {
    padding-top: 10px !important;
  }
}

/*.input-group .btn-sm {
  padding: 8px 10px 3px 10px !important;
}*/

.field-help,
.field-help:link,
.field-help:focus {
  /* font-size: 0.9rem;
  color: #3b3331;
  margin: 8px 8px 0px 8px; */
  margin: 8px 8px 0 12px !important;
}

.btn-outline-secondary,
.btn-outline-secondary.focus,
.btn-outline-secondary:focus,
.btn-outline-secondary.active,
.btn-outline-secondary:active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active {
  color: #3b3331 !important;
  background-color: #fff !important;
  background-image: none !important;
  border-color: #3b3331 !important;
}

.btn-outline-secondary:hover,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #ffffff !important;
  background-color: #141414 !important;
  border-color: #141414 !important;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
}

.btn-outline-secondary.disabled,
.btn-outline-secondary.link,
.btn-outline-secondary.hover,
.btn-outline-secondary.focus,
.btn-outline-secondary.active,
.btn-outline-secondary:disabled,
.btn-outline-secondary[disabled],
.btn-outline-secondary[disabled]:hover,
.btn-outline-secondary[disabled]:focus,
.btn-outline-secondary[disabled]:active {
  color: #b5adad !important;
  background-color: #fff !important;
  border-color: #b5adad !important;
}

.btn,
button {
  font-family: WellsFargoSansSemiBold, Verdana, Geneva, sans-serif !important;
  font-size: 16px !important;
  line-height: 20px !important;
  border-radius: 8px !important;
  border-width: 2px !important;
  padding: 9px 30px 9px 30px !important;
  margin: 8px 16px !important;
  height: 40px !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.MuiInputBase-input {
  height: 2.1876em !important;
  color: #343a40 !important;
}

.MuiFilledInput-underline:after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  /*border-bottom: 2px solid #640B8A !important;*/
  pointer-events: none;
}

.MuiInputBase-root {
  font-size: 1.12rem !important;
  /*padding-top: -28px !important;*/
  font-family: WellsFargoSans, Verdana, Geneva, sans-serif !important;

  border-bottom: solid 0px #787070 !important;
}

.MuiFormLabel-root {
  margin-top: 28px !important;
  font-size: 1.12rem !important;
  /*color: #787070 ;*/
}

.MuiFormControl-root {
  padding: 0px 0px 0.75px 0px !important;
  width: 100% !important;
}

.MuiInputLabel-filled {
  z-index: 1;
  transform: translate(8px, 3px) scale(1) !important;
  pointer-events: none;
}

.MuiFilledInput-input {
  padding: 30px 12px 10px !important;
}

.MuiInputLabel-shrink {
  transform: translate(12px, -22px) scale(0.75) !important;
}

.btn-block-0 {
  display: inline-block;
  width: auto;
}

@media (max-width: 570px) {
  .btn,
  button {
    width: 100%;
    display: block;
    margin: 5px 0;
  }
}

@media (max-width: 570px) {
  .continueBtn {
    width: auto;
    display: block;
    margin: 5px 0;
  }
}

/* Display - MD+ */
@media screen and (min-width: 768px) {
  h1,
  .h1 {
    font-size: 40px;
    font-family: WellsFargoSerif, Georgia, Times, "Times New Roman", serif;
  }

  h2,
  .h2 {
    font-size: 24px;
  }
  .text-divider-yellow-md-0 {
    background: none !important;
  }
}

.MuiFormLabel-root.Mui-focused {
  padding-top: 52px !important;
  color: #5a469b !important;
  font-size: 14px !important;
}

.MuiFilledInput-underline:before {
  border-bottom: 0px solid rgba(0, 0, 0, 0.42);
}

@media screen and (-ms-high-contrast: none) {
  /* IE11 specific styles go here */
  .btn,
  button {
    /* padding-top: 12px; */
  }
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.displayNoneDiv {
  display: none !important;
}

.validate-error,
.has-hover-label.validate-error,
.has-hover-label.validate-error:hover,
.has-hover-label.validate-error:active,
.has-hover-label.validate-error:focus {
  background-color: #fcf7f6 !important;
  border-bottom: solid 1px #bf2d19 !important;
}

.MuiFormHelperText-root.MuiFormHelperText-contained.Mui-error {
  /* margin-left: 0.25rem 0 0 8px !important; */
  margin: 0.25rem 0 0 12px !important;
}

.modal {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.modal-xxl {
  max-width: 95%;
}

.modal-title {
  margin: 0;
  padding: 0;
  font-size: 18px;
  color: black;
}

.modal-header .close {
  font-size: 2.5rem;
  width: auto;
  display: inline;
}

.text-sm-right {
  text-align: right !important;
}

@media (min-width: 576px) {
  .text-sm-right {
    text-align: right !important;
  }
}

@media (max-width: 576px) {
  .modal.fade .modal-dialog {
    margin: 0.5px;
    height: 100%;
  }
  .modal .modal-dialog .modal-content {
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    /* height: 100%; */
  }

  .btnContinue {
    margin-right: 28px !important;
  }

  .modal-footer > :not(:last-child) {
    margin-right: 0.25rem !important;
  }
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.btnContinue {
  margin-right: 28px !important;
}

a:not(.btn):hover {
  text-decoration: inherit;
  color: #5174b8;
}

a:not(.btn):hover:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

a:not(.btn):focus {
  text-decoration: none;
}

/* .text-center {
  text-align: center !important;
} */

.fa-exclamation-circle:before {
  content: "\f06a";
}

.MuiFormLabel-root.Mui-error {
  color: #bf2d19 !important;
}

.has-hover-label.validate-error .hover-label,
.has-hover-label.validate-error:hover .hover-label,
.has-hover-label.validate-error:active .hover-label,
.has-hover-label.validate-error:focus .hover-label,
.validate-error .col-form-label,
.validate-error .text-label {
  color: #bf2d19 !important;
}

.has-hover-label .hover-label {
  position: absolute;
  z-index: inherit;
  top: 2.6rem;
  left: 8px;
  font-size: 1.12rem !important;
  color: #3b3331;
  margin: 0;
}
.MuiFormHelperText-root.Mui-error {
  color: #bf2d19 !important;
  font-family: "Wells Fargo Sans", Verdana, Geneva, sans-serif !important;
  font-size: 1rem;
}

.MuiFilledInput-underline.Mui-error:after {
  transform: scaleX(1);
  border-bottom: 1px solid #bf2d19 !important;
}

.MuiFilledInput-underline.Mui-focused {
  border-bottom-color: #5a469b !important;
}

.imgEqHousing {
  max-height: 10px;
}

.confirmThanku {
  font-family: WellsFargoSerif, Georgia, Times, "Times New Roman", serif;
}


.dce-msg-container .dce-msg-icon .fa.msg-error {
  color: #bf2d19;
}



.dce-msg-container .dce-msg-icon {
  padding: 12px;
}

.dce-msg-container .dce-msg-icon .fa {
  font-size: 40px;
}

.dce-msg-container .dce-msg-text {
  padding: 16px 16px 16px 0;
}

.dce-bgpage {
  background-position: left top;
  background-repeat: repeat-x;
}
.dce-msg-container .dce-msg-icon .fa.msg-alert,
.dce-msg-container .dce-msg-icon .fa.msg-urgent {
  color: #d73f26;
}

.dce-msg-container .dce-msg-icon .fa.msg-confirm {
  color: #178757;
}

.dce-msg-container .dce-msg-icon .fa.msg-error {
  color: #bf2d19;
}

.dce-msg-container .dce-msg-icon .fa.msg-info,
.dce-msg-container .dce-msg-icon .fa.msg-disaster {
  color: #5a469b;
}

.dce-msg-container .dce-msg-text {
  padding: 16px 16px 16px 0;
}

@media screen and (-ms-high-contrast: none) {
  /* IE11 specific styles go here */
  .btn-sm {
    /*padding-top: 10px !important;*/
  }
}

@media (max-width: 576px) {
  .modal .modal-dialog .modal-content {
  }
}

.container-mastfoot .list-inline,
.container-mastfoot .list-inline-item,
.container-mastfoot-mobile {
  line-height: 16px;
}

.container-mastfoot .mastfoot-links {
  margin: 0 0 24px 0;
}

.container-mastfoot .list-inline-item {
  margin-bottom: 16px;
}

.container-mastfoot .list-inline,
.container-mastfoot .list-inline-item,
.container-mastfoot-mobile {
  line-height: 16px;
}

a:not(.btn) {
  transition: color 0.13s;
  text-decoration: underline;
}

a,
a:visited,
a:link {
  cursor: pointer;
}

.container-mastfoot,
.container-mastfoot a,
.container-mastfoot a:hover,
.container-mastfoot-mobile,
.container-mastfoot-mobile a,
.container-mastfoot-mobile a:hover,
.container-slavehead-mobile a.navbar-toggle .mastfoot-copyright {
  color: #3b3331 !important;
  /* font-weight: 200 !important; */
}
.container-masthead .nav a,
.container-masthead-mobile a.navbar-toggle,
.container-masthead-small a.navbar-toggle,
.container-mastfoot,
.container-mastfoot a,
.container-mastfoot a:hover,
.container-mastfoot-mobile,
.container-mastfoot-mobile a,
.container-mastfoot-mobile a:hover {
  color: #fff;
  margin: 0;
}
a:not(.btn) {
  -webkit-transition: color 0.13s;
  transition: color 0.13s;
  text-decoration: underline;
  color: #5a469b;
}
a,
a:visited,
a:link {
  cursor: pointer;
}
a,
button,
input {
  -ms-touch-action: none !important;
}
a,
button {
  transition: all 0.3s linear;
}
a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

a:-webkit-any-link {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
}

.container-masthead .nav,
.container-slavehead .nav,
.container-masthead-mobile .nav,
.container-masthead-small .nav,
.container-mastfoot,
.container-mastfoot-mobile {
  font-size: 13px !important;
}

.container-mastfoot .mastfoot-copyright {
  margin: 32px 0 0 0;
}

p {
  white-space: pre-line;
  word-break: break-word;
}

.container-mastfoot-mobile {
  padding: 24px;
}

.container-mastfoot-mobile .mastfoot-links {
  padding: 0;
  margin: 0 0 24px 0;
  border-bottom: 1px solid #b5adad;
}

.container-mastfoot-mobile .list-inline-item:nth-child(odd) {
  margin-right: 12px;
}

.container-mastfoot-mobile .list-inline-item {
  width: 47%;
  margin: 0 0 24px 0;
}

.container-mastfoot-mobile .mastfoot-footnotes {
  padding: 0 0 24px;
  margin: 0 0 16px 0;
  border-bottom: 1px solid #b5adad;
}

.container-mastfoot-mobile .mastfoot-copyright {
  margin: 0;
}

body {
  min-height: auto;
}

/* Loading */

.bg-load {
  position: fixed;

  left: 0;

  top: 0;

  width: 100%;

  height: 100%;

  background: #fff;

  background-color: rgba(0, 0, 0, 0.4);

  z-index: 978;
}

.bg-load-wrapper {
  /* Size and position */

  font-size: 1.1rem;

  width: 35em;

  height: 13em;

  padding-top: 3em;

  position: fixed;

  left: 40%;

  top: 50%;

  margin-top: -100px;

  margin-left: -100px;

  z-index: 979;

  /* Styles */

  background-color: #fff;

  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);

  border: solid 2px #9c9191;

  border-radius: 2px;

  /* Font styles */

  color: #3b3331;

  text-align: center;

  line-height: 50px;
}

.bg-load-spinner {
  color: initial;

  display: inline-block;

  position: absolute;

  width: 80px;

  height: 80px;

  left: 50%;

  top: 50vh;

  z-index: 979;
}

.bg-load-spinner div {
  transform-origin: 40px 40px;

  animation: bg-load-spinner 1.2s linear infinite;
}

.bg-load-spinner div:after {
  content: " ";

  display: block;

  position: absolute;

  top: 3px;

  left: 37px;

  width: 6px;

  height: 18px;

  border-radius: 20%;

  background: #787070;
}

.bg-load-spinner div:nth-child(1) {
  transform: rotate(0deg);

  animation-delay: -1.1s;
}

.bg-load-spinner div:nth-child(2) {
  transform: rotate(30deg);

  animation-delay: -1s;
}

.bg-load-spinner div:nth-child(3) {
  transform: rotate(60deg);

  animation-delay: -0.9s;
}

.bg-load-spinner div:nth-child(4) {
  transform: rotate(90deg);

  animation-delay: -0.8s;
}

.bg-load-spinner div:nth-child(5) {
  transform: rotate(120deg);

  animation-delay: -0.7s;
}

.bg-load-spinner div:nth-child(devil) {
  transform: rotate(150deg);

  animation-delay: -0.6s;
}

.bg-load-spinner div:nth-child(7) {
  transform: rotate(180deg);

  animation-delay: -0.5s;
}

.bg-load-spinner div:nth-child(music) {
  transform: rotate(210deg);

  animation-delay: -0.4s;
}

.bg-load-spinner div:nth-child(9) {
  transform: rotate(240deg);

  animation-delay: -0.3s;
}

.bg-load-spinner div:nth-child(10) {
  transform: rotate(270deg);

  animation-delay: -0.2s;
}

.bg-load-spinner div:nth-child(11) {
  transform: rotate(300deg);

  animation-delay: -0.1s;
}

.bg-load-spinner div:nth-child(12) {
  transform: rotate(330deg);

  animation-delay: 0s;
}

@keyframes bg-load-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@media (min-width: 1198px) {
  .container-mastfoot .list-inline-item-linebreak {
    display: none;
  }
}

.container-mastfoot .mastfoot-footnotes {
  margin: 0;
}

.container-mastfoot .mastfoot-copyright {
  margin: 32px 0 0 0;
}

.container-mastfoot-mobile {
  padding: 24px;
}

.container-mastfoot-mobile .mastfoot-links {
  padding: 0;
  margin: 0 0 24px 0;
  border-bottom: 1px solid #b5adad;
}

.container-mastfoot-mobile .list-inline-item {
  width: 47%;
  margin: 0 0 24px 0;
}

.container-mastfoot-mobile .list-inline-item:nth-child(odd) {
  margin-right: 12px;
}

.container-mastfoot-mobile .mastfoot-footnotes {
  padding: 0 0 24px;
  margin: 0 0 16px 0;
  border-bottom: 1px solid #b5adad;
}

.container-mastfoot-mobile .mastfoot-copyright {
  margin: 0;
}

/* Base formatting */
.font-sans {
  font-family: "Wells Fargo Sans", Verdana, Geneva, sans-serif !important;
}

.font-sans-light {
  font-family: "Wells Fargo Sans Light", Verdana, Geneva, sans-serif !important;
}

.font-sans-semibold {
  font-family: "Wells Fargo Sans SemiBold", Verdana, Geneva, sans-serif !important;
}

.font-serif {
  font-family: "Wells Fargo Serif", Georgia, Times, "Times New Roman", serif !important;
}

.font-serif-light {
  font-family: "Wells Fargo Serif Light", Georgia, Times, "Times New Roman",
    serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.card-header {
  margin: 0;
  font-family: "Wells Fargo Serif", Georgia, Times, "Times New Roman", serif !important;
  font-weight: 300;
  line-height: 1.3em;
}

.text-gray {
  color: #3b3331 !important;
}

.text-red,
.text-consumer {
  color: #d71e28 !important;
}

.text-darkred {
  color: #d73f26 !important;
}

.text-lightgray {
  color: #787070 !important;
}

.text-white {
  color: #fff !important;
}

.text-dblue {
  color: #00698c !important;
}

.text-tpb {
  color: #946e3a !important;
}

.text-xs {
  font-size: 0.6rem;
}

.text-sm {
  font-size: 0.7rem;
}

.text-md {
  font-size: 0.8rem;
}

.text-lg {
  font-size: 0.9rem;
}

.text-bold {
  font-weight: bold;
}

.text-semibold {
  font-weight: 500;
}

.text-italic {
  font-style: italic;
}

.text-underline {
  text-decoration: underline;
}

.text-strike {
  text-decoration: line-through;
}

.text-divider-yellow {
  background-position: bottom center;
  background-repeat: no-repeat;
  background-image: url(./global/img/div-yellow.png);
  background-size: auto;
}

.text-divider-yellow-0 {
  background: none !important;
}

@media screen and (min-width: 576px) {
  .text-divider-yellow-sm-0 {
    background: none !important;
  }
}
@media screen and (min-width: 768px) {
  .text-divider-yellow-md-0 {
    background: none !important;
  }
}
@media screen and (min-width: 992px) {
  .text-divider-yellow-lg-0 {
    background: none !important;
  }
}
@media screen and (min-width: 1200px) {
  .text-divider-yellow-xl-0 {
    background: none !important;
  }
}

/* Display - XS & SM */
h1,
.h1 {
  padding: 10px 0;
  font-size: 30px;
}

/*h1 alt,
.h1 alt {
  font-size: 24px;
}*/

h2,
.h2 {
  padding: 10px 0;
  font-size: 34px;
  font-family: "Wells Fargo Sans", Verdana, Geneva, sans-serif !important;
}

h3,
.h3 {
  padding: 10px 0;
  font-size: 20px;
  font-family: "Wells Fargo Sans", Verdana, Geneva, sans-serif !important;
}

h4,
.h4 {
  padding: 6px 0;
  font-size: 16px;
  font-family: "Wells Fargo Sans", Verdana, Geneva, sans-serif !important;
}

h5,
.h5 {
  margin: 3px 0;
  font-size: 15px;
  font-family: "Wells Fargo Sans", Verdana, Geneva, sans-serif !important;
}

h6,
.h6 {
  padding: 3px 0;
  font-size: 14px;
  font-family: "Wells Fargo Sans", Verdana, Geneva, sans-serif !important;
}

/* Display - MD+ */
@media screen and (min-width: 768px) {
  h1,
  .h1 {
    font-size: 40px;
  }
  /*h1 alt,
  .h1 alt {
    font-size: 34px;
  }*/
  h2,
  .h2 {
    font-size: 24px;
  }
}
p {
  white-space: pre-line;
  word-break: break-word;
}

.divider-line {
  border-top: 2px solid #e2dede;
}

a,
a:visited,
a:link {
  cursor: pointer;
}

a.disabled,
a:disabled,
a.disabled:hover {
  font-style: italic;
  text-decoration: none !important;
  cursor: not-allowed;
}

a:hover {
  text-decoration: underline;
}

a,
button {
  transition: all 0.3s linear;
}

a:active,
a:focus,
a:hover,
button,
button:active,
button:focus,
embed,
input::-moz-focus-inner,
object {
  outline: 0;
}

p a:focus {
  outline: 2px solid #5A469B;
}


a,
button,
input {
  -ms-touch-action: none !important;
}

.btn,
button {
  font-family: WellsFargoSansSemiBold, Verdana, Geneva, sans-serif;
  font-size: 16px;
  line-height: 20px;
  border-radius: 8px;
  border-width: 2px;
  padding: 9px 30px 9px 30px;
  margin: 8px 16px;
  height: 40px;
  outline: 0 !important;
  box-shadow: none !important;
}

@media (max-width: 570px) {
  .btn,
  button {
    width: 100%;
    display: block;
    margin: 8px 0;
  }
}

@media screen and (-ms-high-contrast: none) {
  /* IE11 specific styles go here */
  .btn,
  button {
    padding-top: 12px;
  }
}

a:not(.btn) {
  -webkit-transition: color 0.13s;
  transition: color 0.13s;
  text-decoration: underline;
  color: #5a469b;
}

a:not(.btn):hover,
a:not(.btn):focus,
a:not(.btn):active {
  text-decoration: underline;
  color: #141414;
}

a:not(.btn):hover:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.btn:hover,
button:hover {
  text-decoration: underline;
}

.btn.disabled,
.btn:disabled,
.btn[disabled] {
  pointer-events: none;
  text-decoration: none;
}

.btn-primary,
.btn-primary.focus,
.btn-primary:focus,
.btn-primary.active,
.btn-primary:active,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
a.dropdown-item.active,
a.dropdown-item:active,
button.dropdown-item.active,
button.dropdown-item:active {
  color: #fff;
  background-color: #d71e28 !important;
  border-color: #d71e28 !important;
}

.btn-primary:focus,
.btn-primary.focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
  text-decoration: underline;
}

.btn-primary:hover,
.show > .btn-primary.dropdown-toggle {
  background-color: #87190A !important;
  border-color: #87190A !important;
  color: #fff ;
}

.btn.disabled,
.btn.disabled:link,
.btn.disabled:hover,
.btn.disabled:focus,
.btn.disabled:active,
.btn[disabled],
.btn[disabled]:hover,
.btn[disabled]:focus,
.btn[disabled]:active {
  background-color: #efa5a9;
  border-color: #efa5a9;
  opacity: .65;
  color: #fff;
}

.btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary:active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active {
  color: #d71e28;
  background-color: #fff;
  background-image: none;
  border-color: #d71e28;
}

.btn-outline-primary:hover,
.show > .btn-outline-primary.dropdown-toggle {
  color: #ab181f;
  background-color: #fff;
  border-color: #ab181f;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
  text-decoration: underline;
}

.btn-outline-primary.disabled,
.btn-outline-primary.link,
.btn-outline-primary.hover,
.btn-outline-primary.focus,
.btn-outline-primary.active,
.btn-outline-primary:disabled,
.btn-outline-primary[disabled],
.btn-outline-primary[disabled]:hover,
.btn-outline-primary[disabled]:focus,
.btn-outline-primary[disabled]:active {
  color: #ab181f;
  background-color: #fff;
  border-color: #ab181f;
}

.btn-outline-secondary,
.btn-outline-secondary.active,
.btn-outline-secondary:active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active {
  color: #3b3331;
  background-color: #fff;
  background-image: none;
  border-color: #3b3331;
}

.btn-outline-secondary.focus,
.btn-outline-secondary:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
  text-decoration: underline;
}

.btn-outline-secondary:hover,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #3b3331;
  background-color: #fff;
  border-color: #3b3331;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
  text-decoration: underline;
}

.btn-outline-secondary.disabled,
.btn-outline-secondary.link,
.btn-outline-secondary.hover,
.btn-outline-secondary.focus,
.btn-outline-secondary.active,
.btn-outline-secondary:disabled,
.btn-outline-secondary[disabled],
.btn-outline-secondary[disabled]:hover,
.btn-outline-secondary[disabled]:focus,
.btn-outline-secondary[disabled]:active {
  color: #b5adad;
  background-color: #fff;
  border-color: #b5adad;
}

.btn-tpb-primary,
.btn-tpb-primary.active,
.btn-tpb-primary:active,
.btn-tpb-primary:not(:disabled):not(.disabled):active,
.btn-tpb-primary:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #352b6b;
  border-color: #352b6b;
}

.btn-tpb-primary:hover,
.show > .btn-tpb-primary.dropdown-toggle {
  background-color: #352b6b;
  border-color: #352b6b;
  color: #fff;
}

.btn-tpb-primary.focus,
.btn-tpb-primary:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
  text-decoration: underline;
}

.btn-tpb-primary.disabled,
.btn-tpb-primary.disabled:link,
.btn-tpb-primary.disabled:hover,
.btn-tpb-primary.disabled:focus,
.btn-tpb-primary.disabled:active,
.btn-tpb-primary[disabled],
.btn-tpb-primary[disabled]:hover,
.btn-tpb-primary[disabled]:focus,
.btn-tpb-primary[disabled]:active {
  background-color: #aeaac4;
  border-color: #aeaac4;
  opacity: 1;
  color: #fff;
}

.btn-tpb-outline-primary,
.btn-tpb-outline-primary.active,
.btn-tpb-outline-primary:active,
.btn-tpb-outline-primary:not(:disabled):not(.disabled):active,
.btn-tpb-outline-primary:not(:disabled):not(.disabled).active {
  color: #352b6b;
  background-color: #fff;
  background-image: none;
  border-color: #352b6b;
}

.btn-tpb-outline-primary:hover,
.show > .btn-tpb-outline-primary.dropdown-toggle {
  color: #352b6b;
  background-color: #fff;
  border-color: #352b6b;
}

.btn-tpb-outline-primary:focus,
.btn-tpb-outline-primary.focus,
.btn-tpb-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-tpb-outline-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
  text-decoration: underline;
}

.btn-tpb-outline-primary.disabled,
.btn-tpb-outline-primary.link,
.btn-tpb-outline-primary.hover,
.btn-tpb-outline-primary.focus,
.btn-tpb-outline-primary.active,
.btn-tpb-outline-primary:disabled,
.btn-tpb-outline-primary[disabled],
.btn-tpb-outline-primary[disabled]:hover,
.btn-tpb-outline-primary[disabled]:focus,
.btn-tpb-outline-primary[disabled]:active {
  color: #aeaac4;
  background-color: #fff;
  border-color: #aeaac4;
}

.btn-tertiary,
.btn-tertiary.active,
.btn-tertiary:active,
.btn-tertiary:not(:disabled):not(.disabled):active,
.btn-tertiary:not(:disabled):not(.disabled).active {
  color: #5a469b;
  background-color: #fff;
  background-image: none;
  border-color: #fff;
  text-decoration: underline;
}

.btn-tertiary:hover,
.show > .btn-tertiary.dropdown-toggle {
  color: #141414;
  background-color: #fff;
  border-color: #fff;
}

.btn-tertiary:focus,
.btn-tertiary.focus,
.btn-tertiary:not(:disabled):not(.disabled):active:focus,
.btn-tertiary:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
  color: #5a469b;
}

.btn-tertiary.disabled,
.btn-tertiary.link,
.btn-tertiary.hover,
.btn-tertiary.focus,
.btn-tertiary.active,
.btn-tertiary:disabled,
.btn-tertiary[disabled],
.btn-tertiary[disabled]:hover,
.btn-tertiary[disabled]:focus,
.btn-tertiary[disabled]:active {
  color: #b5adad;
  background-color: #fff;
  border-color: #fff;
}

.btn-sm {
  padding: 8px 15px 5px 15px !important;
  line-height: 18px !important;
  height: auto !important;
}

@media screen and (-ms-high-contrast: none) {
  /* IE11 specific styles go here */
  .btn-sm {
    padding-top: 10px;
  }
}

.rounded {
  border-radius: 0.5rem !important;
}

.pagination .btn {
  padding: 4px 8px 2px 8px;
  margin: 0;
  line-height: 20px;
  height: auto;
  font-size: 1rem;
  border-radius: 0;
}

.pagination .page-item.active .page-link {
  color: #d71e28;
  background-color: #fff;
  background-image: none;
  border-color: #d71e28;
}

.pagination .page-item .page-link:hover {
  color: #bb0826;
  background-color: #fff;
  border-color: #bb0826;
}


.form-control:focus,
.custom-select:focus,
input:focus,
input[type="text"]:focus,
input:required,
input:required:focus,
input:required:hover {
  outline-style: none;
  box-shadow: none;
  border-color: #ff9657;
}

.form-control,
.form-control:active,
.form-control:focus {
  background-color: transparent;
  box-shadow: none !important;
}

.form-control-plaintext,
.form-control-plaintext:active,
.form-control-plaintext:focus,
.form-control-plaintext:hover {
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
  background-color: transparent;
  border-width: 0 !important;
  font-weight: bold;
}

.input-group-plaintext {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
}

.input-group .btn-sm {
  padding: 8px 10px 3px 10px;
}

.input-group-prepend .btn,
.input-group-append .btn,
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child) {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.custom-select {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%233b3331' d='M2 3L0 1h4z'/%3E%3C/svg%3E");
}

.errorfield {
  color: #ae1e23;
}

.errorcontrol,
.errorcontrol:focus {
  background-color: #ffefda;
  border: 1px solid #ae1e23;
  color: #ae1e23;
  box-shadow: none;
}

.reqfield {
  color: #ae1e23;
}

.reqfield:before {
  content: "*";
  color: #ae1e23;
  font-weight: bold;
  vertical-align: bottom;
}

select.form-control.is-invalid {
  background-image: none;
}

.radio,
.checkbox {
  display: none;
}

.radio + span,
.checkbox + span {
  padding: 0;
  padding-right: 10px;
}

.radio + span:before,
.checkbox + span:before {
  font-family: FontAwesome;
  font-size: 21px;
  display: inline-block;
  vertical-align: middle;
  letter-spacing: 10px;
  color: #c8d0da;
}

.radio:checked + span:before,
.checkbox:checked + span:before {
  color: #3b3331;
}

.radio:disabled + span:before,
.checkbox:disabled + span:before {
  opacity: 0.5;
  cursor: not-allowed;
}

.radio:checked:disabled + span:before,
.checkbox:checked:disabled + span:before {
  color: #c8d0da;
}

.checkbox + span:before {
  content: "\f0c8";
}

.checkbox:checked + span:before {
  content: "\f14a";
}

.checkbox.rounded + span:before {
  content: "\f111";
}

.checkbox.rounded:checked + span:before {
  content: "\f058";
}

.radio + span:before {
  content: "\f111";
}

.radio:checked + span:before {
  content: "\f192";
}

.radio.squared + span:before {
  content: "\f0c8";
}

.radio.squared:checked + span:before {
  content: "\f14a";
}

.custom-radio.custom-control-inline {
  padding-left: 2.5rem;
  margin-right: 2.29rem;
  padding-bottom: 1.5rem;
}

@media (max-width: 576px) {
  .custom-radio.custom-control-inline {
    display: block;
  }
}

.custom-radio .custom-control-label {
  font-size: 1.075rem;
  color: #3b3331;
  outline: none;
}

.custom-radio .custom-control-label:focus,
.custom-radio .custom-control-label::before:focus,
.custom-radio .custom-control-label::after:focus {
  background-color: transparent;
  outline: none;
}

.custom-radio .custom-control-label::before {
  width: 1.86rem;
  height: 1.86rem;
  margin-right: 8px;
  left: -2.58rem;
  top: -0.24rem;
  border: solid 2px #5a469b;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #5a469b;
  border-color: #5a469b;
}

.custom-radio .custom-control-label::after {
  width: 2rem;
  height: 2rem;
  left: -2.65rem;
  top: -0.32rem;
}

/* IE11 fixes */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .custom-radio .custom-control-label::before {
    top: -0.4rem;
  }

  .custom-radio .custom-control-label::after {
    top: -0.45rem;
  }
}

.custom-checkbox .wf-checkbox {
  display: none;
}

.custom-checkbox .wf-checkbox + label {
  margin: 0;
  padding: 0;
}

.custom-checkbox .wf-checkbox + label.fa,
.custom-checkbox label.fa {
  color: #3b3331;
  font-size: 24px;
  cursor: pointer;
  margin-right: 8px;
  margin-top: -4px;
}

/* IE11 fixes */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .custom-checkbox .wf-checkbox + label.fa,
  .custom-checkbox label.fa {
    margin-top: -6px;
  }
}

.custom-checkbox .wf-checkbox + label.fa:before {
  display: inline-block;
  content: "\f096";
  cursor: pointer;
}

.custom-checkbox .wf-checkbox:checked + label.fa:before {
  content: "\f14a";
  position: relative;
  color: #5a469b;
}

.custom-checkbox .wf-checkbox[disabled],
.custom-checkbox .wf-checkbox[disabled] + label.fa {
  cursor: default;
  color: #beb6d8;
}

.custom-checkbox .text-label {
  position: relative;
  top: -4px;
}

.form-check-label {
  margin-top: 0.2rem;
}

.form-control::-webkit-input-placeholder {
  font-style: italic;
  color: #c8d0da;
}

.form-control:-moz-placeholder {
  /* Firefox 18- */
  font-style: italic;
  color: #d7dde4;
}

.form-control::-moz-placeholder {
  /* Firefox 19+ */
  font-style: italic;
  color: #d7dde4;
}

.form-control:-ms-input-placeholder {
  font-style: italic;
  color: #d7dde4;
}

.form-control-static {
  padding: calc(0.375rem + 1px) 0 0 0rem;
  font-weight: bold;
}

.has-hover-label {
  position: relative;
  /*padding-top: 1rem;*/
  padding: 16px 8px 0.75px 8px;
  margin: 0;
  border-bottom: solid 1px #5a469b !important;
}

.has-hover-label:hover,
.has-hover-label:focus,
.has-hover-label:active {
  background-color: #f9f7f6 !important;
  border-bottom: solid 1px #5a469b !important;
  transition: all 0.2s linear;
}

.has-hover-label.filled {
  background-color: #fff !important;
  border-color: #fff !important;
  border-bottom: solid 1px #3b3331 !important;
}

.has-hover-label.focused {
  background-color: #f9f7f6 !important;
  border-bottom: solid 2px #5a469b !important;
}

.has-hover-label .hover-label {
  position: absolute;
  z-index: inherit;
  top: 2.2rem;
  left: 8px;
  font-size: 1.12rem;
  color: #3b3331;
  margin: 0;
}

.has-hover-label.filled .hover-label,
.has-hover-label.focused .hover-label {
  top: 0.8rem;
  font-size: 0.93rem;
  transition: all 0.2s linear;
  color: #3b3331;
}

.has-hover-label.focused .hover-label {
  color: #5a469b;
}

.has-hover-label .hover-label .hover-help {
  position: absolute;
  z-index: inherit;
  top: 0rem;
  right: 2px;
  font-size: 1.12rem;
  color: #3b3331;
  margin: 0;
  padding: 0;
}

.has-hover-label .hover-field {
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
  margin: 0;
  border: 0;
  opacity: 0;
  color: #3b3331;
}

.has-hover-label.focused .hover-field,
.has-hover-label.focused .input-group-prepend,
.has-hover-label.focused .input-group-append,
.has-hover-label.filled .hover-field,
.has-hover-label.filled .input-group-prepend,
.has-hover-label.filled .input-group-append {
  opacity: 1;
  transition: all 0.2s linear;
}

.has-hover-label .input-group-prepend,
.has-hover-label .input-group-append {
  margin: 0;
  opacity: 0;
}
.has-hover-label .input-group-append {
  opacity: 1;
}

.has-hover-label .input-group-prepend > .input-group-text,
.has-hover-label .input-group-append > .input-group-text {
  font-size: inherit;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin: 0;
  background-color: transparent !important;
  border: 0;
  border-radius: 0 !important;
}

.has-hover-label .input-group-prepend > .input-group-text .fa,
.has-hover-label .input-group-append > .input-group-text .fa {
  font-size: 1.2em;
}

.has-hover-label.validate-error,
.has-hover-label.validate-error:hover,
.has-hover-label.validate-error:active,
.has-hover-label.validate-error:focus {
  background-color: #fcf7f6 !important;
  border-bottom: solid 1px #bf2d19 !important;
}

.has-hover-label.validate-error .hover-label,
.has-hover-label.validate-error:hover .hover-label,
.has-hover-label.validate-error:active .hover-label,
.has-hover-label.validate-error:focus .hover-label,
.validate-error .col-form-label,
.validate-error .text-label {
  color: #bf2d19 !important;
}

a.balloon-help,
a.balloon-help:link,
a.balloon-help:focus {
  text-decoration: underline dashed #3b3331;
  -webkit-text-decoration: underline dashed #3b3331;
  font-size: 0.9rem;
  color: #3b3331;
  margin: 8px 8px 0px 8px;
}

@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  a.balloon-help,
  a.balloon-help:link,
  a.balloon-help:focus {
    text-decoration: underline;
  }
}

.popover {
  color: #3b3331;
  font-size: 1rem;
  border: 1px solid #e2dede;
  border-radius: 2px;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(20, 20, 20, 0.15);
  -moz-box-shadow: 0px 2px 4px 0px rgba(20, 20, 20, 0.15);
  box-shadow: 0px 2px 4px 0px rgba(20, 20, 20, 0.15);
  padding: 16px;
  /* background-image: url('../img/icon-close.png'); */
  background-position: top right;
  background-repeat: no-repeat;
  background-origin: content-box;
}

.popover-header {
  padding: 0;
  margin: 0 0 12px 0;
  font-size: 1rem;
  font-family: "Wells Fargo Sans SemiBold", Verdana, Geneva, sans-serif !important;
  color: inherit;
  border: 0;
  background-color: transparent;
  border-radius: 0;
  padding-right: 40px;
  cursor: pointer;
}

.popover-body {
  padding: 0;
  color: inherit;
}

.validate-error.invalid-feedback {
  color: #bf2d19 !important;
  font-size: 1rem;
  padding-left: 8px;
}

.field-validate-pass .fa.fa-check {
  color: #009e0f;
}

.field-validate-fail .fa.fa-times {
  color: #cf2a27;
}

.field-risk-blank .fa {
  display: none;
}

.field-risk-no .fa,
.field-risk-no .form-control-static {
  color: #009e0f;
}

.field-risk-no .form-control {
  background-color: #b6d7a8;
  border-color: #009e0f;
}

.field-risk-lo .fa,
.field-risk-lo .form-control-static {
  color: #f1c232;
}

.field-risk-lo .form-control {
  background-color: #ffe599;
  border-color: #f1c232;
}

.field-risk-md .fa,
.field-risk-md .form-control-static {
  color: #ff9900;
}

.field-risk-md .form-control {
  background-color: #f9cb9c;
  border-color: #ff9900;
}

.field-risk-hi .fa,
.field-risk-hi .form-control-static {
  color: #cc0000;
}

.field-risk-hi .form-control {
  background-color: #ea9999;
  border-color: #cc0000;
}

.field-help,
.field-help:link,
.field-help:focus {
  /* color: #3b3331;
  margin: 8px 8px 0px 8px; */
  margin: 8px 8px 0 8px !important;
}

table.table {
  font-size: 0.8rem;
}

table.table .btn {
  display: inline;
  font-size: inherit;
  line-height: normal;
  height: auto;
  border-radius: 0;
}

.modal {
  padding-right: 0 !important;
}

.modal-xxl {
  max-width: 95%;
}

.modal-title {
  margin: 0;
  padding: 0;
  font-size: 18px;
}

.modal-header {
  border: 0;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}

.modal-header .close {
  font-size: 2.5rem;
  width: auto;
  display: inline;
}

.modal-header .title-close:focus {
  outline: 2px solid #5A469B !important;
}

.modal-footer {
  border: 0;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  padding: 0.5rem;
}

.modal-content {
  /* border: solid 1px #e2dede; */
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
}

.modal-body {
  border-top: solid 1px #e2dede;
  border-bottom: solid 1px #e2dede;
  padding: 1rem;
}

@media (max-width: 576px) {
  .modal.fade .modal-dialog {
  }
  .modal .modal-dialog .modal-content {
  }
}

.card {
  transition: all 0.2s linear;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
}

@media (min-width: 768px) {
  /* Guttering fix for specific wf-cards */
  .card {
    margin-bottom: 26px;
  }
}

.card-body {
  padding: 0.8rem 0.9rem 0.7rem 0.9rem;
  border-top: solid 1px rgba(0, 0, 0, 0.125);
}

.card-header {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  background-color: #eeeeee;
  border-bottom: 0;
}

.card-header .btn {
  line-height: normal;
  height: auto;
  font-size: 0.75rem;
  margin-right: 2px;
}

.card-header .btn .fa {
  font-size: 0.85rem;
}

.card-footer {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

.card .list-group-flush .list-group-item {
  padding: 0.25rem 0.75rem;
}

/* Loading */
.bg-load {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 978;
}

.bg-load-wrapper {
  /* Size and position */
  font-size: 1.1rem;
  width: 35em;
  height: 13em;
  padding-top: 3em;
  position: fixed;
  left: 40%;
  top: 50%;
  margin-top: -100px;
  margin-left: -100px;
  z-index: 979;
  /* Styles */
  background-color: #fff;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border: solid 2px #9c9191;
  border-radius: 2px;
  /* Font styles */
  color: #3b3331;
  text-align: center;
  line-height: 50px;
}

.bg-load-spinner {
  color: initial;
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
  left: 50%;
  top: 50vh;
  z-index: 979;
}

.bg-load-spinner div {
  transform-origin: 40px 40px;
  animation: bg-load-spinner 1.2s linear infinite;
}

.bg-load-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #787070;
}

.bg-load-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.bg-load-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.bg-load-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.bg-load-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.bg-load-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.bg-load-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.bg-load-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.bg-load-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.bg-load-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.bg-load-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.bg-load-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.bg-load-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes bg-load-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* master head - navigation */
.container-masthead .nav,
.container-slavehead .nav,
.container-masthead-mobile .nav,
.container-masthead-small .nav,
.container-mastfoot,
.container-mastfoot-mobile {
  font-size: 13px;
}

.container-masthead .nav a,
.container-masthead-mobile a.navbar-toggle,
.container-masthead-small a.navbar-toggle,
.container-mastfoot,
.container-mastfoot a,
.container-mastfoot a:hover,
.container-mastfoot-mobile,
.container-mastfoot-mobile a,
.container-mastfoot-mobile a:hover {
  color: #fff;
  margin: 0;
}

.container-mastfoot,
.container-mastfoot a,
.container-mastfoot a:hover,
.container-mastfoot-mobile,
.container-mastfoot-mobile a,
.container-mastfoot-mobile a:hover,
.container-slavehead-mobile a.navbar-toggle {
  color: #3b3331;
  /* font-weight: 200; */
}

.container-mastfoot .mastfoot-footnotes a,
.container-mastfoot .mastfoot-footnotes a:link,
.container-mastfoot .mastfoot-footnotes a:active {
  text-decoration: underline;
}

.container-masthead .nav .nav-item {
  padding: 20px 0;
}

.container-masthead .nav a {
  line-height: 16px;
  padding-top: 0;
  padding-bottom: 0;
}

.container-masthead .nav .nav-item:last-child a.nav-link {
  padding-right: 0;
}

.container-masthead .nav .nav-link:disabled,
.container-masthead-mobile .nav .nav-link:disabled,
.container-masthead-small .nav .nav-link:disabled,
.container-masthead .nav .nav-link.disabled,
.container-masthead-mobile .nav .nav-link.disabled,
.container-masthead-small .nav .nav-link.disabled,
.container-masthead .nav .nav-link[disabled],
.container-masthead-mobile .nav .nav-link[disabled],
.container-masthead-small .nav .nav-link[disabled] {
  color: #f4f0ed;
  font-style: italic;
}

.container-slavehead .nav a,
.container-slavehead-mobile .nav a,
.container-masthead-mobile .nav a,
.container-masthead-small .nav a {
  color: #3b3331;
  margin: 0;
}

.container-masthead .nav .active,
.container-slavehead .nav .active,
.container-slavehead-mobile .nav .active,
.container-masthead-mobile .nav .active,
.container-masthead-small .nav .active {
  font-weight: bold;
  font-style: italic;
  text-decoration: underline;
}

.container-masthead .nav a:hover,
.container-masthead-mobile .nav a:hover,
.container-masthead-small .nav a:hover,
.container-slavehead-mobile .nav a:hover,
.container-mastfoot .list-inline a:hover,
.container-mastfoot-mobile a:hover {
  text-decoration: underline;
}

.container-slavehead .nav {
  font-size: 16px;
  line-height: 20px;
  font-family: "Wells Fargo Sans Light", Verdana, Geneva, sans-serif !important;
}

.container-slavehead .nav a.nav-link {
  padding: 10px 0;
  margin: 0 20px;
}

.container-slavehead .nav .nav-item:first-child a.nav-link {
  margin-left: 0;
}

.container-slavehead .nav .nav-link.active,
.container-slavehead .nav a.nav-link:hover {
  padding-bottom: 8px;
  border-bottom: solid 2px #ffcd41;
}

.container-mastfoot .list-inline,
.container-mastfoot .list-inline-item,
.container-mastfoot-mobile {
  line-height: 16px;
}

.container-mastfoot .list-inline-item {
  margin-bottom: 16px;
}

.container-mastfoot .divider {
  padding: 0 2px;
}

.container-mastfoot-mobile .copyright {
  line-height: 1.5rem;
}

.container-slavehead .title,
.container-slavehead-mobile .title {
  font-size: 16px;
  font-family: "Wells Fargo Serif", Georgia, Times, "Times New Roman", serif !important;
  font-weight: 600;
  margin: 0;
  padding: 8px 0 7px 0;
  line-height: 25px;
}

.container-slavehead .content {
  padding-top: 0.7rem;
}

.container-masthead-mobile .nav .nav-item,
.container-masthead-small .nav .nav-item,
.container-slavehead-mobile .nav .nav-item {
  border-bottom: solid 1px #fff;
}

.container-mastfoot .title {
  margin: 0;
  padding: 0;
  border: 0;
}

.container-mastfoot .copyright .fa-circle {
  font-size: 4px;
  vertical-align: middle;
}

.carousel-container {
  position: relative;
  z-index: 949;
  margin-top: -80px;
}

@media (min-width: 992px) {
  .carousel-container {
    margin-top: -100px;
  }
}

@media (min-width: 768px) {
  .carousel-container {
    margin-top: -97px;
  }
}

.carousel .carousel-image1,
.carousel .carousel-image2,
.carousel .carousel-image3,
.carousel .carousel-image4,
.carousel .carousel-image5,
.carousel .carousel-image6,
.carousel .carousel-image7,
.carousel .carousel-image8,
.carousel .carousel-image9,
.carousel .carousel-image10,
.carousel .carousel-image11,
.carousel .carousel-image12,
.carousel .carousel-image13,
.carousel .carousel-image14,
.carousel .carousel-image15,
.carousel .carousel-image16,
.carousel .carousel-image17 {
  height: 500px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.carousel .carousel-caption {
  bottom: 30%;
}

@media (max-width: 768px) {
  .carousel .carousel-caption {
    bottom: 20%;
  }
}

@media (max-width: 576px) {
  .carousel .carousel-caption {
    bottom: 25%;
  }
}

.carousel-container .carousel .carousel-control {
  display: none;
}

.marketing-container {
  margin-bottom: 0;
  min-height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  overflow-x: hidden;
  overflow: hidden;
}

.marketing-container .titles-container {
  position: relative;
  z-index: 2;
}

.marketing-container[data-card-product="cashwise"],
.marketing-container[data-card-product="platinum"],
.marketing-container[data-card-product="propel"] {
  min-height: 0;
  padding: 0;
  background-size: contain;
  position: relative;
  background-color: transparent;
}

@media (min-width: 768px) {
  .marketing-container {
    min-height: 230px;
  }

  .marketing-container:before {
    position: absolute;
    content: "";
    width: 100%;
    bottom: 0;
    left: 0;
    height: 120px;
  }

  .marketing-container:after {
    z-index: 1;
    position: absolute;
    content: "";
    width: 100%;
    bottom: 120px;
    left: 0;
    height: 246px;
  }

  .marketing-container[data-card-product="cashwise"]:before {
    background: #ffcd41;
    background: -moz-linear-gradient(
      90deg,
      rgba(255, 205, 65, 1) 5%,
      rgba(255, 205, 65, 1) 5%,
      rgba(215, 30, 40, 1) 5%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(255, 205, 65, 1) 5%,
      rgba(255, 205, 65, 1) 5%,
      rgba(215, 30, 40, 1) 5%
    );
    background: linear-gradient(
      90deg,
      rgba(255, 205, 65, 1) 5%,
      rgba(255, 205, 65, 1) 5%,
      rgba(215, 30, 40, 1) 5%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFCD41",endColorstr="#D71E28",GradientType=1);
  }

  .marketing-container[data-card-product="cashwise"]:after {
    background: #efa216;
    background: -moz-linear-gradient(
      90deg,
      rgba(239, 162, 22, 1) 5%,
      rgba(239, 162, 22, 0) 5%,
      rgba(255, 255, 255, 1) 5%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(239, 162, 22, 1) 5%,
      rgba(239, 162, 22, 0) 5%,
      rgba(255, 255, 255, 1) 5%
    );
    background: linear-gradient(
      90deg,
      rgba(239, 162, 22, 1) 5%,
      rgba(239, 162, 22, 0) 5%,
      rgba(255, 255, 255, 1) 5%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#efa216",endColorstr="#ffffff",GradientType=1);
  }

  .marketing-container[data-card-product="platinum"]:before {
    background: #8b8e8e;
    background: -moz-linear-gradient(
      90deg,
      rgba(139, 142, 142, 1) 5%,
      rgba(139, 142, 142, 1) 5%,
      rgba(215, 30, 40, 1) 5%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(139, 142, 142, 1) 5%,
      rgba(139, 142, 142, 1) 5%,
      rgba(215, 30, 40, 1) 5%
    );
    background: linear-gradient(
      90deg,
      rgba(139, 142, 142, 1) 5%,
      rgba(139, 142, 142, 1) 5%,
      rgba(215, 30, 40, 1) 5%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#8b8e8e",endColorstr="#D71E28",GradientType=1);
  }

  .marketing-container[data-card-product="platinum"]:after {
    background: #555757;
    background: -moz-linear-gradient(
      90deg,
      rgba(85, 87, 87, 1) 5%,
      rgba(85, 87, 87, 0) 5%,
      rgba(255, 255, 255, 1) 5%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(85, 87, 87, 1) 5%,
      rgba(85, 87, 87, 0) 5%,
      rgba(255, 255, 255, 1) 5%
    );
    background: linear-gradient(
      90deg,
      rgba(85, 87, 87, 1) 5%,
      rgba(85, 87, 87, 0) 5%,
      rgba(255, 255, 255, 1) 5%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#555757",endColorstr="#ffffff",GradientType=1);
  }

  .marketing-container[data-card-product="propel"]:before {
    background: #951c54;
    background: -moz-linear-gradient(
      90deg,
      rgba(149, 28, 84, 1) 5%,
      rgba(149, 28, 84, 1) 5%,
      rgba(215, 30, 40, 1) 5%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(149, 28, 84, 1) 5%,
      rgba(149, 28, 84, 1) 5%,
      rgba(215, 30, 40, 1) 5%
    );
    background: linear-gradient(
      90deg,
      rgba(149, 28, 84, 1) 5%,
      rgba(149, 28, 84, 1) 5%,
      rgba(215, 30, 40, 1) 5%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#951c54",endColorstr="#D71E28",GradientType=1);
  }

  .marketing-container[data-card-product="propel"]:after {
    background: #630c2a;
    background: -moz-linear-gradient(
      90deg,
      rgba(99, 12, 42, 1) 5%,
      rgba(99, 12, 42, 0) 5%,
      rgba(255, 255, 255, 1) 5%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(99, 12, 42, 1) 5%,
      rgba(99, 12, 42, 0) 5%,
      rgba(255, 255, 255, 1) 5%
    );
    background: linear-gradient(
      90deg,
      rgba(99, 12, 42, 1) 5%,
      rgba(99, 12, 42, 0) 5%,
      rgba(255, 255, 255, 1) 5%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#630c2a",endColorstr="#ffffff",GradientType=1);
  }
}

@media (min-width: 992px) {
  .marketing-container {
    min-height: 180px;
    padding-top: 10px;
  }

  .marketing-container:before {
    height: 50px;
  }

  .marketing-container:after {
    bottom: 50px;
    height: 170px;
  }

  .marketing-container[data-card-product="cashwise"]:before {
    background: -moz-linear-gradient(
      90deg,
      rgba(255, 205, 65, 1) calc(((100vw - 1440px) / 2) + 290px),
      rgba(255, 205, 65, 1) calc(((100vw - 1440px) / 2) + 290px),
      rgba(215, 30, 40, 1) calc(((100vw - 1440px) / 2) + 290px)
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(255, 205, 65, 1) calc(((100vw - 1440px) / 2) + 290px),
      rgba(255, 205, 65, 1) calc(((100vw - 1440px) / 2) + 290px),
      rgba(215, 30, 40, 1) calc(((100vw - 1440px) / 2) + 290px)
    );
    background: linear-gradient(
      90deg,
      rgba(255, 205, 65, 1) calc(((100vw - 1440px) / 2) + 290px),
      rgba(255, 205, 65, 1) calc(((100vw - 1440px) / 2) + 290px),
      rgba(215, 30, 40, 1) calc(((100vw - 1440px) / 2) + 290px)
    );
  }

  .marketing-container[data-card-product="cashwise"]:after {
    background: -moz-linear-gradient(
      90deg,
      rgba(239, 162, 22, 1) calc(((100vw - 1440px) / 2) + 290px),
      rgba(239, 162, 22, 0) calc(((100vw - 1440px) / 2) + 290px),
      rgba(255, 255, 255, 1) calc(((100vw - 1440px) / 2) + 290px)
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(239, 162, 22, 1) calc(((100vw - 1440px) / 2) + 290px),
      rgba(239, 162, 22, 0) calc(((100vw - 1440px) / 2) + 290px),
      rgba(255, 255, 255, 1) calc(((100vw - 1440px) / 2) + 290px)
    );
    background: linear-gradient(
      90deg,
      rgba(239, 162, 22, 1) calc(((100vw - 1440px) / 2) + 290px),
      rgba(239, 162, 22, 0) calc(((100vw - 1440px) / 2) + 290px),
      rgba(255, 255, 255, 1) calc(((100vw - 1440px) / 2) + 290px)
    );
  }

  .marketing-container[data-card-product="platinum"]:before {
    height: 30px;
    background: -moz-linear-gradient(
      90deg,
      rgba(139, 142, 142, 1) calc(((100vw - 1440px) / 2) + 290px),
      rgba(139, 142, 142, 1) calc(((100vw - 1440px) / 2) + 290px),
      rgba(215, 30, 40, 1) calc(((100vw - 1440px) / 2) + 290px)
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(139, 142, 142, 1) calc(((100vw - 1440px) / 2) + 290px),
      rgba(139, 142, 142, 1) calc(((100vw - 1440px) / 2) + 290px),
      rgba(215, 30, 40, 1) calc(((100vw - 1440px) / 2) + 290px)
    );
    background: linear-gradient(
      90deg,
      rgba(139, 142, 142, 1) calc(((100vw - 1440px) / 2) + 290px),
      rgba(139, 142, 142, 1) calc(((100vw - 1440px) / 2) + 290px),
      rgba(215, 30, 40, 1) calc(((100vw - 1440px) / 2) + 290px)
    );
  }

  .marketing-container[data-card-product="platinum"]:after {
    bottom: 30px;
    height: 180px;
    background: -moz-linear-gradient(
      90deg,
      rgba(85, 87, 87, 1) calc(((100vw - 1440px) / 2) + 290px),
      rgba(85, 87, 87, 0) calc(((100vw - 1440px) / 2) + 290px),
      rgba(255, 255, 255, 1) calc(((100vw - 1440px) / 2) + 290px)
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(85, 87, 87, 1) calc(((100vw - 1440px) / 2) + 290px),
      rgba(85, 87, 87, 0) calc(((100vw - 1440px) / 2) + 290px),
      rgba(255, 255, 255, 1) calc(((100vw - 1440px) / 2) + 290px)
    );
    background: linear-gradient(
      90deg,
      rgba(85, 87, 87, 1) calc(((100vw - 1440px) / 2) + 290px),
      rgba(85, 87, 87, 0) calc(((100vw - 1440px) / 2) + 290px),
      rgba(255, 255, 255, 1) calc(((100vw - 1440px) / 2) + 290px)
    );
  }

  .marketing-container[data-card-product="propel"]:before {
    background: -moz-linear-gradient(
      90deg,
      rgba(149, 28, 84, 1) calc(((100vw - 1440px) / 2) + 290px),
      rgba(149, 28, 84, 1) calc(((100vw - 1440px) / 2) + 290px),
      rgba(215, 30, 40, 1) calc(((100vw - 1440px) / 2) + 290px)
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(149, 28, 84, 1) calc(((100vw - 1440px) / 2) + 290px),
      rgba(149, 28, 84, 1) calc(((100vw - 1440px) / 2) + 290px),
      rgba(215, 30, 40, 1) calc(((100vw - 1440px) / 2) + 290px)
    );
    background: linear-gradient(
      90deg,
      rgba(149, 28, 84, 1) calc(((100vw - 1440px) / 2) + 290px),
      rgba(149, 28, 84, 1) calc(((100vw - 1440px) / 2) + 290px),
      rgba(215, 30, 40, 1) calc(((100vw - 1440px) / 2) + 290px)
    );
  }

  .marketing-container[data-card-product="propel"]:after {
    background: -moz-linear-gradient(
      90deg,
      rgba(99, 12, 42, 1) calc(((100vw - 1440px) / 2) + 290px),
      rgba(99, 12, 42, 0) calc(((100vw - 1440px) / 2) + 290px),
      rgba(255, 255, 255, 1) calc(((100vw - 1440px) / 2) + 290px)
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(99, 12, 42, 1) calc(((100vw - 1440px) / 2) + 290px),
      rgba(99, 12, 42, 0) calc(((100vw - 1440px) / 2) + 290px),
      rgba(255, 255, 255, 1) calc(((100vw - 1440px) / 2) + 290px)
    );
    background: linear-gradient(
      90deg,
      rgba(99, 12, 42, 1) calc(((100vw - 1440px) / 2) + 290px),
      rgba(99, 12, 42, 0) calc(((100vw - 1440px) / 2) + 290px),
      rgba(255, 255, 255, 1) calc(((100vw - 1440px) / 2) + 290px)
    );
  }
}

@media all and (min-width: 992px) and (-ms-high-contrast: none),
  (-ms-high-contrast: active) {
  .marketing-container[data-card-product="cashwise"]:before {
    background: -moz-linear-gradient(
      90deg,
      rgba(255, 205, 65, 1) 9%,
      rgba(255, 205, 65, 1) 9%,
      rgba(215, 30, 40, 1) 9%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(255, 205, 65, 1) 9%,
      rgba(255, 205, 65, 1) 9%,
      rgba(215, 30, 40, 1) 9%
    );
    background: linear-gradient(
      90deg,
      rgba(255, 205, 65, 1) 9%,
      rgba(255, 205, 65, 1) 9%,
      rgba(215, 30, 40, 1) 9%
    );
  }

  .marketing-container[data-card-product="cashwise"]:after {
    background: -moz-linear-gradient(
      90deg,
      rgba(239, 162, 22, 1) 9%,
      rgba(239, 162, 22, 0) 9%,
      rgba(255, 255, 255, 1) 9%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(239, 162, 22, 1) 9%,
      rgba(239, 162, 22, 0) 9%,
      rgba(255, 255, 255, 1) 9%
    );
    background: linear-gradient(
      90deg,
      rgba(239, 162, 22, 1) 9%,
      rgba(239, 162, 22, 0) 9%,
      rgba(255, 255, 255, 1) 9%
    );
  }

  .marketing-container[data-card-product="platinum"]:before {
    background: -moz-linear-gradient(
      90deg,
      rgba(139, 142, 142, 1) 9%,
      rgba(139, 142, 142, 1) 9%,
      rgba(215, 30, 40, 1) 9%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(139, 142, 142, 1) 9%,
      rgba(139, 142, 142, 1) 9%,
      rgba(215, 30, 40, 1) 9%
    );
    background: linear-gradient(
      90deg,
      rgba(139, 142, 142, 1) 9%,
      rgba(139, 142, 142, 1) 9%,
      rgba(215, 30, 40, 1) 9%
    );
  }

  .marketing-container[data-card-product="platinum"]:after {
    background: -moz-linear-gradient(
      90deg,
      rgba(85, 87, 87, 1) 9%,
      rgba(85, 87, 87, 0) 9%,
      rgba(255, 255, 255, 1) 9%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(85, 87, 87, 1) 9%,
      rgba(85, 87, 87, 0) 9%,
      rgba(255, 255, 255, 1) 9%
    );
    background: linear-gradient(
      90deg,
      rgba(85, 87, 87, 1) 9%,
      rgba(85, 87, 87, 0) 9%,
      rgba(255, 255, 255, 1) 9%
    );
  }

  .marketing-container[data-card-product="propel"]:before {
    background: -moz-linear-gradient(
      90deg,
      rgba(149, 28, 84, 1) 9%,
      rgba(149, 28, 84, 1) 9%,
      rgba(215, 30, 40, 1) 9%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(149, 28, 84, 1) 9%,
      rgba(149, 28, 84, 1) 9%,
      rgba(215, 30, 40, 1) 9%
    );
    background: linear-gradient(
      90deg,
      rgba(149, 28, 84, 1) 9%,
      rgba(149, 28, 84, 1) 9%,
      rgba(215, 30, 40, 1) 9%
    );
  }

  .marketing-container[data-card-product="propel"]:after {
    background: -moz-linear-gradient(
      90deg,
      rgba(99, 12, 42, 1) 9%,
      rgba(99, 12, 42, 0) 9%,
      rgba(255, 255, 255, 1) 9%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(99, 12, 42, 1) 9%,
      rgba(99, 12, 42, 0) 9%,
      rgba(255, 255, 255, 1) 9%
    );
    background: linear-gradient(
      90deg,
      rgba(99, 12, 42, 1) 9%,
      rgba(99, 12, 42, 0) 9%,
      rgba(255, 255, 255, 1) 9%
    );
  }
}

@media all and (min-width: 1220px) and (-ms-high-contrast: none),
  (-ms-high-contrast: active) {
  .marketing-container[data-card-product="cashwise"]:before {
    background: -moz-linear-gradient(
      90deg,
      rgba(255, 205, 65, 1) 16%,
      rgba(255, 205, 65, 1) 16%,
      rgba(215, 30, 40, 1) 16%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(255, 205, 65, 1) 16%,
      rgba(255, 205, 65, 1) 16%,
      rgba(215, 30, 40, 1) 16%
    );
    background: linear-gradient(
      90deg,
      rgba(255, 205, 65, 1) 16%,
      rgba(255, 205, 65, 1) 16%,
      rgba(215, 30, 40, 1) 16%
    );
  }

  .marketing-container[data-card-product="cashwise"]:after {
    background: -moz-linear-gradient(
      90deg,
      rgba(239, 162, 22, 1) 16%,
      rgba(239, 162, 22, 0) 16%,
      rgba(255, 255, 255, 1) 16%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(239, 162, 22, 1) 16%,
      rgba(239, 162, 22, 0) 16%,
      rgba(255, 255, 255, 1) 16%
    );
    background: linear-gradient(
      90deg,
      rgba(239, 162, 22, 1) 16%,
      rgba(239, 162, 22, 0) 16%,
      rgba(255, 255, 255, 1) 16%
    );
  }

  .marketing-container[data-card-product="platinum"]:before {
    background: -moz-linear-gradient(
      90deg,
      rgba(139, 142, 142, 1) 16%,
      rgba(139, 142, 142, 1) 16%,
      rgba(215, 30, 40, 1) 16%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(139, 142, 142, 1) 16%,
      rgba(139, 142, 142, 1) 16%,
      rgba(215, 30, 40, 1) 16%
    );
    background: linear-gradient(
      90deg,
      rgba(139, 142, 142, 1) 16%,
      rgba(139, 142, 142, 1) 16%,
      rgba(215, 30, 40, 1) 16%
    );
  }

  .marketing-container[data-card-product="platinum"]:after {
    background: -moz-linear-gradient(
      90deg,
      rgba(85, 87, 87, 1) 16%,
      rgba(85, 87, 87, 0) 16%,
      rgba(255, 255, 255, 1) 16%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(85, 87, 87, 1) 16%,
      rgba(85, 87, 87, 0) 16%,
      rgba(255, 255, 255, 1) 16%
    );
    background: linear-gradient(
      90deg,
      rgba(85, 87, 87, 1) 16%,
      rgba(85, 87, 87, 0) 16%,
      rgba(255, 255, 255, 1) 16%
    );
  }

  .marketing-container[data-card-product="propel"]:before {
    background: -moz-linear-gradient(
      90deg,
      rgba(149, 28, 84, 1) 16%,
      rgba(149, 28, 84, 1) 16%,
      rgba(215, 30, 40, 1) 16%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(149, 28, 84, 1) 16%,
      rgba(149, 28, 84, 1) 16%,
      rgba(215, 30, 40, 1) 16%
    );
    background: linear-gradient(
      90deg,
      rgba(149, 28, 84, 1) 16%,
      rgba(149, 28, 84, 1) 16%,
      rgba(215, 30, 40, 1) 16%
    );
  }

  .marketing-container[data-card-product="propel"]:after {
    background: -moz-linear-gradient(
      90deg,
      rgba(99, 12, 42, 1) 16%,
      rgba(99, 12, 42, 0) 16%,
      rgba(255, 255, 255, 1) 16%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(99, 12, 42, 1) 16%,
      rgba(99, 12, 42, 0) 16%,
      rgba(255, 255, 255, 1) 16%
    );
    background: linear-gradient(
      90deg,
      rgba(99, 12, 42, 1) 16%,
      rgba(99, 12, 42, 0) 16%,
      rgba(255, 255, 255, 1) 16%
    );
  }
}

@media all and (min-width: 1440px) and (-ms-high-contrast: none),
  (-ms-high-contrast: active) {
  .marketing-container[data-card-product="cashwise"]:before {
    background: -moz-linear-gradient(
      90deg,
      rgba(255, 205, 65, 1) 22%,
      rgba(255, 205, 65, 1) 22%,
      rgba(215, 30, 40, 1) 22%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(255, 205, 65, 1) 22%,
      rgba(255, 205, 65, 1) 22%,
      rgba(215, 30, 40, 1) 22%
    );
    background: linear-gradient(
      90deg,
      rgba(255, 205, 65, 1) 22%,
      rgba(255, 205, 65, 1) 22%,
      rgba(215, 30, 40, 1) 22%
    );
  }

  .marketing-container[data-card-product="cashwise"]:after {
    background: -moz-linear-gradient(
      90deg,
      rgba(239, 162, 22, 1) 22%,
      rgba(239, 162, 22, 0) 22%,
      rgba(255, 255, 255, 1) 22%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(239, 162, 22, 1) 22%,
      rgba(239, 162, 22, 0) 22%,
      rgba(255, 255, 255, 1) 22%
    );
    background: linear-gradient(
      90deg,
      rgba(239, 162, 22, 1) 22%,
      rgba(239, 162, 22, 0) 22%,
      rgba(255, 255, 255, 1) 22%
    );
  }

  .marketing-container[data-card-product="platinum"]:before {
    background: -moz-linear-gradient(
      90deg,
      rgba(139, 142, 142, 1) 22%,
      rgba(139, 142, 142, 1) 22%,
      rgba(215, 30, 40, 1) 22%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(139, 142, 142, 1) 22%,
      rgba(139, 142, 142, 1) 22%,
      rgba(215, 30, 40, 1) 22%
    );
    background: linear-gradient(
      90deg,
      rgba(139, 142, 142, 1) 22%,
      rgba(139, 142, 142, 1) 22%,
      rgba(215, 30, 40, 1) 22%
    );
  }

  .marketing-container[data-card-product="platinum"]:after {
    background: -moz-linear-gradient(
      90deg,
      rgba(85, 87, 87, 1) 22%,
      rgba(85, 87, 87, 0) 22%,
      rgba(255, 255, 255, 1) 22%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(85, 87, 87, 1) 22%,
      rgba(85, 87, 87, 0) 22%,
      rgba(255, 255, 255, 1) 22%
    );
    background: linear-gradient(
      90deg,
      rgba(85, 87, 87, 1) 22%,
      rgba(85, 87, 87, 0) 22%,
      rgba(255, 255, 255, 1) 22%
    );
  }

  .marketing-container[data-card-product="propel"]:before {
    background: -moz-linear-gradient(
      90deg,
      rgba(149, 28, 84, 1) 22%,
      rgba(149, 28, 84, 1) 22%,
      rgba(215, 30, 40, 1) 22%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(149, 28, 84, 1) 22%,
      rgba(149, 28, 84, 1) 22%,
      rgba(215, 30, 40, 1) 22%
    );
    background: linear-gradient(
      90deg,
      rgba(149, 28, 84, 1) 22%,
      rgba(149, 28, 84, 1) 22%,
      rgba(215, 30, 40, 1) 22%
    );
  }

  .marketing-container[data-card-product="propel"]:after {
    background: -moz-linear-gradient(
      90deg,
      rgba(99, 12, 42, 1) 22%,
      rgba(99, 12, 42, 0) 22%,
      rgba(255, 255, 255, 1) 22%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(99, 12, 42, 1) 22%,
      rgba(99, 12, 42, 0) 22%,
      rgba(255, 255, 255, 1) 22%
    );
    background: linear-gradient(
      90deg,
      rgba(99, 12, 42, 1) 22%,
      rgba(99, 12, 42, 0) 22%,
      rgba(255, 255, 255, 1) 22%
    );
  }
}

@media all and (min-width: 1600px) and (-ms-high-contrast: none),
  (-ms-high-contrast: active) {
  .marketing-container[data-card-product="cashwise"]:before {
    background: -moz-linear-gradient(
      90deg,
      rgba(255, 205, 65, 1) 25%,
      rgba(255, 205, 65, 1) 25%,
      rgba(215, 30, 40, 1) 25%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(255, 205, 65, 1) 25%,
      rgba(255, 205, 65, 1) 25%,
      rgba(215, 30, 40, 1) 25%
    );
    background: linear-gradient(
      90deg,
      rgba(255, 205, 65, 1) 25%,
      rgba(255, 205, 65, 1) 25%,
      rgba(215, 30, 40, 1) 25%
    );
  }

  .marketing-container[data-card-product="cashwise"]:after {
    background: -moz-linear-gradient(
      90deg,
      rgba(239, 162, 22, 1) 25%,
      rgba(239, 162, 22, 0) 25%,
      rgba(255, 255, 255, 1) 25%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(239, 162, 22, 1) 25%,
      rgba(239, 162, 22, 0) 25%,
      rgba(255, 255, 255, 1) 25%
    );
    background: linear-gradient(
      90deg,
      rgba(239, 162, 22, 1) 25%,
      rgba(239, 162, 22, 0) 25%,
      rgba(255, 255, 255, 1) 25%
    );
  }

  .marketing-container[data-card-product="platinum"]:before {
    background: -moz-linear-gradient(
      90deg,
      rgba(139, 142, 142, 1) 25%,
      rgba(139, 142, 142, 1) 25%,
      rgba(215, 30, 40, 1) 25%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(139, 142, 142, 1) 25%,
      rgba(139, 142, 142, 1) 25%,
      rgba(215, 30, 40, 1) 25%
    );
    background: linear-gradient(
      90deg,
      rgba(139, 142, 142, 1) 25%,
      rgba(139, 142, 142, 1) 25%,
      rgba(215, 30, 40, 1) 25%
    );
  }

  .marketing-container[data-card-product="platinum"]:after {
    background: -moz-linear-gradient(
      90deg,
      rgba(85, 87, 87, 1) 25%,
      rgba(85, 87, 87, 0) 25%,
      rgba(255, 255, 255, 1) 25%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(85, 87, 87, 1) 25%,
      rgba(85, 87, 87, 0) 25%,
      rgba(255, 255, 255, 1) 25%
    );
    background: linear-gradient(
      90deg,
      rgba(85, 87, 87, 1) 25%,
      rgba(85, 87, 87, 0) 25%,
      rgba(255, 255, 255, 1) 25%
    );
  }

  .marketing-container[data-card-product="propel"]:before {
    background: -moz-linear-gradient(
      90deg,
      rgba(149, 28, 84, 1) 25%,
      rgba(149, 28, 84, 1) 25%,
      rgba(215, 30, 40, 1) 25%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(149, 28, 84, 1) 25%,
      rgba(149, 28, 84, 1) 25%,
      rgba(215, 30, 40, 1) 25%
    );
    background: linear-gradient(
      90deg,
      rgba(149, 28, 84, 1) 25%,
      rgba(149, 28, 84, 1) 25%,
      rgba(215, 30, 40, 1) 25%
    );
  }

  .marketing-container[data-card-product="propel"]:after {
    background: -moz-linear-gradient(
      90deg,
      rgba(99, 12, 42, 1) 25%,
      rgba(99, 12, 42, 0) 25%,
      rgba(255, 255, 255, 1) 25%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(99, 12, 42, 1) 25%,
      rgba(99, 12, 42, 0) 25%,
      rgba(255, 255, 255, 1) 25%
    );
    background: linear-gradient(
      90deg,
      rgba(99, 12, 42, 1) 25%,
      rgba(99, 12, 42, 0) 25%,
      rgba(255, 255, 255, 1) 25%
    );
  }
}

@media all and (min-width: 2050px) and (-ms-high-contrast: none),
  (-ms-high-contrast: active) {
  .marketing-container[data-card-product="cashwise"]:before {
    background: -moz-linear-gradient(
      90deg,
      rgba(255, 205, 65, 1) 30%,
      rgba(255, 205, 65, 1) 30%,
      rgba(215, 30, 40, 1) 30%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(255, 205, 65, 1) 30%,
      rgba(255, 205, 65, 1) 30%,
      rgba(215, 30, 40, 1) 30%
    );
    background: linear-gradient(
      90deg,
      rgba(255, 205, 65, 1) 30%,
      rgba(255, 205, 65, 1) 30%,
      rgba(215, 30, 40, 1) 30%
    );
  }

  .marketing-container[data-card-product="cashwise"]:after {
    background: -moz-linear-gradient(
      90deg,
      rgba(239, 162, 22, 1) 30%,
      rgba(239, 162, 22, 0) 30%,
      rgba(255, 255, 255, 1) 30%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(239, 162, 22, 1) 30%,
      rgba(239, 162, 22, 0) 30%,
      rgba(255, 255, 255, 1) 30%
    );
    background: linear-gradient(
      90deg,
      rgba(239, 162, 22, 1) 30%,
      rgba(239, 162, 22, 0) 30%,
      rgba(255, 255, 255, 1) 30%
    );
  }

  .marketing-container[data-card-product="platinum"]:before {
    background: -moz-linear-gradient(
      90deg,
      rgba(139, 142, 142, 1) 30%,
      rgba(139, 142, 142, 1) 30%,
      rgba(215, 30, 40, 1) 30%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(139, 142, 142, 1) 30%,
      rgba(139, 142, 142, 1) 30%,
      rgba(215, 30, 40, 1) 30%
    );
    background: linear-gradient(
      90deg,
      rgba(139, 142, 142, 1) 30%,
      rgba(139, 142, 142, 1) 30%,
      rgba(215, 30, 40, 1) 30%
    );
  }

  .marketing-container[data-card-product="platinum"]:after {
    background: -moz-linear-gradient(
      90deg,
      rgba(85, 87, 87, 1) 30%,
      rgba(85, 87, 87, 0) 30%,
      rgba(255, 255, 255, 1) 30%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(85, 87, 87, 1) 30%,
      rgba(85, 87, 87, 0) 30%,
      rgba(255, 255, 255, 1) 30%
    );
    background: linear-gradient(
      90deg,
      rgba(85, 87, 87, 1) 30%,
      rgba(85, 87, 87, 0) 30%,
      rgba(255, 255, 255, 1) 30%
    );
  }

  .marketing-container[data-card-product="propel"]:before {
    background: -moz-linear-gradient(
      90deg,
      rgba(149, 28, 84, 1) 30%,
      rgba(149, 28, 84, 1) 30%,
      rgba(215, 30, 40, 1) 30%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(149, 28, 84, 1) 30%,
      rgba(149, 28, 84, 1) 30%,
      rgba(215, 30, 40, 1) 30%
    );
    background: linear-gradient(
      90deg,
      rgba(149, 28, 84, 1) 30%,
      rgba(149, 28, 84, 1) 30%,
      rgba(215, 30, 40, 1) 30%
    );
  }

  .marketing-container[data-card-product="propel"]:after {
    background: -moz-linear-gradient(
      90deg,
      rgba(99, 12, 42, 1) 30%,
      rgba(99, 12, 42, 0) 30%,
      rgba(255, 255, 255, 1) 30%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(99, 12, 42, 1) 30%,
      rgba(99, 12, 42, 0) 30%,
      rgba(255, 255, 255, 1) 30%
    );
    background: linear-gradient(
      90deg,
      rgba(99, 12, 42, 1) 30%,
      rgba(99, 12, 42, 0) 30%,
      rgba(255, 255, 255, 1) 30%
    );
  }
}

.userlogin-container,
.userlogout-container,
.intro-container,
.content-container {
  position: relative;
  z-index: 959;
}

.userlogin-container {
  margin-top: -5%;
}

.userlogout-container {
  margin-top: -6%;
}

@media (max-width: 576px) {
  .userlogin-container {
    margin-top: -20%;
  }

  .userlogout-container {
    margin-top: -18%;
  }
}

.intro-container {
  margin-top: -8%;
}

.alert-dismissible .close {
  margin: 0;
}

/* Scroll2Top */
.scroll-top-wrapper {
  position: fixed;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  text-align: center;
  z-index: 989;
  background-color: #d71e28;
  color: #fff;
  line-height: 2rem;
  right: 10%;
  bottom: 0px;
  padding: 0.5rem 0.8rem 0 0.8rem;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  -o-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.scroll-top-wrapper:hover {
  background-color: #ffcd41;
  line-height: 3rem;
}

.scroll-top-wrapper.show {
  visibility: visible;
  cursor: pointer;
  opacity: 1;
}

.scroll-top-wrapper i.fa {
  line-height: inherit;
}

.bg-red .has-float-label .form-control {
  border-color: #fff !important;
}

.bg-red .has-float-label .form-control:placeholder-shown:not(:focus) + * {
  opacity: 1;
}

.bg-red .has-float-label .custom-select {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23ffffff' d='M2 3L0 1h4z'/%3E%3C/svg%3E");
}

/* Vertical Sidemenu Bar */
.nav-sidebar-v {
  position: fixed;
  top: 0;
  left: -500px;
  display: inline-block;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 400px;
  z-index: 999;
  overflow-y: auto;
  overflow-x: hidden;
}

/* DateTime Picker */
.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background-color: #d71e28;
}

/* Bootstrap Multiselect */
.multiselectdropdown .btn,
.multiselectdropdown .btn:active,
.multiselectdropdown .btn:focus,
.multiselectdropdown.show > .btn-light.dropdown-toggle {
  background-color: transparent;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  line-height: 1.5;
  color: #495057;
  font-size: 1rem;
  font-weight: 400;
  font-family: "Wells Fargo Sans", Verdana, Geneva, sans-serif !important;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  margin: 0;
}

.bootstrap-select > .dropdown-toggle.bs-placeholder,
.bootstrap-select > .dropdown-toggle.bs-placeholder:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder:active,
.bootstrap-select .dropdown-menu li a span.text {
  color: #495057;
}

.multiselectdropdown .dropdown-menu {
  border: 1px solid #ced4da;
  border-top: 0;
}

.multiselectdropdown .dropdown-menu.inner {
  max-height: 150px;
}

.multiselectdropdown .dropdown-menu li a {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.multiselectdropdown .dropdown-item.active,
.multiselectdropdown .dropdown-item:active {
  background-color: #f8f9fa;
}

/* Kendo UI - Grid */
.k-pager-wrap .k-link,
.k-pager-wrap .k-state-selected {
  padding: 0.2rem;
}

.k-grid-pager {
  border-width: 0;
}

.k-autocomplete .k-input,
.k-dropdown-wrap .k-input,
.k-multiselect-wrap .k-input,
.k-numeric-wrap .k-input,
.k-picker-wrap .k-input,
.k-selectbox .k-input,
.k-textbox > input {
  text-indent: 0;
}

.k-grid-header .k-header > .k-link > .k-icon {
  position: relative;
}

.k-grid-header .k-header > .k-link > .k-icon::before {
  top: -5px;
  right: 0;
}

/*External Links Popup*/
.modal.modal-links {
  top: 0;
}

.modal.modal-links .modal-xxl {
  max-width: 100%;
  margin: 0;
}

@media (min-width: 576px) {
  .modal.modal-links {
    top: 25px;
  }

  .modal.modal-links .modal-xxl {
    margin: 1.75rem 0 0 0;
  }
}

.modal.modal-links .modal-content {
  border-radius: 0;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-color: #dfdfdf;
}

/* Modal Open Hide Content */
.bg-hide .main-wrapper {
  visibility: hidden;
}

/* IE and Chrome Clear X removal */
input[type="text"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type="text"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Pace */
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  background: #ffcd41;
  position: fixed;
  z-index: 99999;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
}

/* Debugger */
.debugger {
  position: fixed;
  overflow: hidden;
  text-align: center;
  z-index: 989;
  background-color: #d73f26;
  color: #fff;
  line-height: 2rem;
  left: 0;
  top: 50%;
  padding: 0.5rem 1rem;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  -o-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.debugger:hover {
  background-color: #a93e00;
  padding: 0.8rem 1.2rem;
  font-size: 1.5rem;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.debugger i.fa {
  line-height: inherit;
}

/* Material Switch Slider */
.material-switch > input[type="checkbox"] {
  display: none;
}

.material-switch > label {
  cursor: pointer;
  height: 0px;
  position: relative;
  width: 30px;
  text-align: left;
  margin-bottom: 0;
}

.material-switch > label::before {
  background-color: #787070;
  border-radius: 16px;
  content: "";
  height: 24px;
  margin-top: -15px;
  position: absolute;
  opacity: 0.3;
  transition: all 0.4s ease-in-out;
  width: 44px;
}

.material-switch > label::after {
  background-color: #fff;
  border-radius: 16px;
  content: "";
  height: 18px;
  left: 4px;
  margin-top: -8px;
  position: absolute;
  top: -4px;
  transition: all 0.3s ease-in-out;
  width: 18px;
}

.material-switch > input[type="checkbox"]:checked + label::before {
  background-color: #007545;
  opacity: 1;
}

.material-switch > input[type="checkbox"]:checked + label::after {
  background: #fff;
  left: 24px;
}

.material-switch.disabled > label {
  cursor: not-allowed;
}

.material-switch.disabled > input[type="checkbox"]:checked + label::before {
  background-color: #99c8b5 !important;
}

.material-switch.disabled > input[type="checkbox"]:checked + label::after {
  background-color: #fff !important;
}

/* Custom WF Tab bar */
.tab-bar-container {
  margin-top: 16px;
}

.tab-bar {
  background: #fff;
}

.tab-bar,
.tab-bar-size-sml {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.tab-bar-size-sml {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.tab-bar-size-sml .tab-bar-enabled {
  opacity: 1;
}

.tab-bar-size-sml .tab-bar-disabled {
  opacity: 0.5;
}

.tab-bar-container .tab-bar-size-lrg {
  display: none;
}

.tab-bar-size-sml .tab-bar-active-step {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 15px;
  padding-bottom: 16px;
}

.tab-bar-size-sml .tab-bar-active-step-title {
  font-size: 1.2rem;
  font-weight: 700;
  color: #44464a;
  padding: 0 16px;
}

.tab-bar .tab-bar-line {
  display: block;
  background: #b4b5b7;
  position: relative;
  height: 4px;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.tab-bar .tab-bar-line:first-child {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.tab-bar .tab-bar-line:last-child {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.tab-bar .tab-bar-line.tab-bar-active {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(50%, #d20826),
    color-stop(50%, #b4b5b7)
  );
  background: linear-gradient(90deg, #d20826 50%, #b4b5b7 0);
}

.tab-bar .tab-bar-line.tab-bar-active:focus {
  outline: 2px solid rgba(81, 116, 184, 0.5);
}

.tab-bar .tab-bar-line.tab-bar-previous {
  background-color: #d20826;
}

.tab-bar .tab-bar-line-indicator.tab-bar-active {
  display: block;
  background-color: #d20826;
  width: 14px;
  height: 14px;
  margin: -5px auto;
  border-radius: 14px;
}

@media screen and (min-width: 48rem) {
  .tab-bar {
    padding: 0;
  }

  .tab-bar-size-sml {
    display: none;
  }

  .tab-bar-container {
    margin: 35px auto 0;
  }

  .tab-bar-container .tab-bar-line > div {
    display: none;
  }

  .tab-bar-container .tab-bar-size-lrg {
    display: block;
    text-align: center;
  }

  .tab-bar-container .tab-bar-size-lrg .tab-bar-step {
    display: inline-block;
    vertical-align: top;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .tab-bar-container .tab-bar-size-lrg .tab-bar-active {
    font-weight: 700;
    min-height: 33px;
    margin: 13px auto;
    text-align: center;
    color: #fff;
  }

  .tab-bar-container .tab-bar-size-lrg .tab-bar-active div {
    display: inline-block;
    padding: 8px 12px;
    margin: -5px auto;
    text-align: center;
    background-color: #44464a;
    border-radius: 5px;
  }

  .tab-bar-container .tab-bar-size-lrg .tab-bar-active:before {
    content: "";
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #44464a;
    position: relative;
    width: 11px;
    display: block;
    top: -5px;
    margin: 0 auto;
  }

  .tab-bar-container .tab-bar-size-lrg .tab-bar-previous {
    margin: 13px auto;
    color: #44464a;
  }

  .tab-bar-container .tab-bar-size-lrg .tab-bar-default {
    margin: 13px auto;
  }

  .tab-bar-container .tab-bar-size-lrg .tab-bar-previous a {
    color: #5174b8;
  }

  .tab-bar-container .tab-bar-size-lrg .tab-bar-default a {
    text-decoration: none;
  }

  .tab-bar-container .tab-bar-size-lrg .tab-bar-default a:hover {
    text-decoration: underline;
  }
}

@media screen and (min-width: 64rem) {
  .tab-bar-container {
    margin: 0 auto;
  }
}

@media screen and (-ms-high-contrast: active) {
  .tab-bar .tab-bar-line-indicator.tab-bar-active {
    background-color: #d20826;
  }

  .tab-bar .tab-bar-line {
    background-color: #b4b5b7;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
  }

  .tab-bar .tab-bar-line.tab-bar-active {
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(50%, #d20826),
      color-stop(50%, #b4b5b7)
    );
    background: linear-gradient(90deg, #d20826 50%, #b4b5b7 0);
  }

  .tab-bar .tab-bar-line.tab-bar-previous {
    background-color: #d20826;
  }
}

/* Responsive Shadows */
.shadow-0 {
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.shadow {
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2) !important;
  -moz-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2) !important;
  -webkit-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2) !important;
}

@media screen and (min-width: 576px) {
  .shadow-sm {
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2) !important;
    -moz-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2) !important;
    -webkit-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2) !important;
  }
}

@media screen and (min-width: 768px) {
  .shadow-md {
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2) !important;
    -moz-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2) !important;
    -webkit-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2) !important;
  }
}

@media screen and (min-width: 992px) {
  .shadow-lg {
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2) !important;
    -moz-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2) !important;
    -webkit-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2) !important;
  }
}

@media screen and (min-width: 1200px) {
  .shadow-xl {
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2) !important;
    -moz-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2) !important;
    -webkit-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2) !important;
  }
}

/* Responsive Borders  */
.border-0 {
  border: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border, .border-1 {
  border: 1px solid #dee2e6 !important;
}

.border-left-1 {
  border-left: 1px solid #dee2e6 !important;
}

.border-right-1 {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom-1 {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-top-1 {
  border-top: 1px solid #dee2e6 !important;
}

.border-2 {
  border: 2px solid #dee2e6 !important;
}

.border-left-2 {
  border-left: 2px solid #dee2e6 !important;
}

.border-right-2 {
  border-right: 2px solid #dee2e6 !important;
}

.border-bottom-2 {
  border-bottom: 2px solid #dee2e6 !important;
}

.border-top-2 {
  border-top: 2px solid #dee2e6 !important;
}

.border-3 {
  border: 3px solid #dee2e6 !important;
}

.border-left-3 {
  border-left: 3px solid #dee2e6 !important;
}

.border-right-3 {
  border-right: 3px solid #dee2e6 !important;
}

.border-bottom-3 {
  border-bottom: 3px solid #dee2e6 !important;
}

.border-top-3 {
  border-top: 3px solid #dee2e6 !important;
}

@media screen and (min-width:576px) {
  .border-sm-1 {
       border: 1px solid #dee2e6 !important;
  }

  .border-sm-0 {
       border: 0 !important;
  }

  .border-sm-left-0 {
       border-left: 0 !important;
  }

  .border-sm-right-0 {
       border-right: 0 !important;
  }

  .border-sm-bottom-0 {
       border-bottom: 0 !important;
  }

  .border-sm-top-0 {
       border-top: 0 !important;
  }

  .border-sm-left-1 {
       border-left: 1px solid #dee2e6 !important;
  }

  .border-sm-right-1 {
       border-right: 1px solid #dee2e6 !important;
  }

  .border-sm-bottom-1 {
       border-bottom: 1px solid #dee2e6 !important;
  }

  .border-sm-top-1 {
       border-top: 1px solid #dee2e6 !important;
  }

  .border-sm-left-2 {
       border-left: 2px solid #dee2e6 !important;
  }

  .border-sm-right-2 {
       border-right: 2px solid #dee2e6 !important;
  }

  .border-sm-bottom-2 {
       border-bottom: 2px solid #dee2e6 !important;
  }

  .border-sm-top-2 {
       border-top: 2px solid #dee2e6 !important;
  }

  .border-sm-left-3 {
       border-left: 3px solid #dee2e6 !important;
  }

  .border-sm-right-3 {
       border-right: 3px solid #dee2e6 !important;
  }

  .border-sm-bottom-3 {
       border-bottom: 3px solid #dee2e6 !important;
  }

  .border-sm-top-3 {
       border-top: 3px solid #dee2e6 !important;
  }
}

@media screen and (min-width:768px) {
  .border-md-1 {
       border: 1px solid #dee2e6 !important;
  }

  .border-md-0 {
       border: 0 !important;
  }

  .border-md-left-0 {
       border-left: 0 !important;
  }

  .border-md-right-0 {
       border-right: 0 !important;
  }

  .border-md-bottom-0 {
       border-bottom: 0 !important;
  }

  .border-md-top-0 {
       border-top: 0 !important;
  }

  .border-md-left-1 {
       border-left: 1px solid #dee2e6 !important;
  }

  .border-md-right-1 {
       border-right: 1px solid #dee2e6 !important;
  }

  .border-md-bottom-1 {
       border-bottom: 1px solid #dee2e6 !important;
  }

  .border-md-top-1 {
       border-top: 1px solid #dee2e6 !important;
  }

  .border-md-left-2 {
       border-left: 2px solid #dee2e6 !important;
  }

  .border-md-right-2 {
       border-right: 2px solid #dee2e6 !important;
  }

  .border-md-bottom-2 {
       border-bottom: 2px solid #dee2e6 !important;
  }

  .border-md-top-3 {
       border-top: 3px solid #dee2e6 !important;
  }

  .border-md-left-3 {
       border-left: 3px solid #dee2e6 !important;
  }

  .border-md-right-3 {
       border-right: 3px solid #dee2e6 !important;
  }

  .border-md-bottom-3 {
       border-bottom: 3px solid #dee2e6 !important;
  }

  .border-md-top-3 {
       border-top: 3px solid #dee2e6 !important;
  }
}

@media screen and (min-width:992px) {
  .border-lg-1 {
       border: 1px solid #dee2e6 !important;
  }

  .border-lg-0 {
       border: 0 !important;
  }

  .border-lg-left-0 {
       border-left: 0 !important;
  }

  .border-lg-right-0 {
       border-right: 0 !important;
  }

  .border-lg-bottom-0 {
       border-bottom: 0 !important;
  }

  .border-lg-top-0 {
       border-top: 0 !important;
  }

  .border-lg-left-1 {
       border-left: 1px solid #dee2e6 !important;
  }

  .border-lg-right-1 {
       border-right: 1px solid #dee2e6 !important;
  }

  .border-lg-bottom-1 {
       border-bottom: 1px solid #dee2e6 !important;
  }

  .border-lg-top-1 {
       border-top: 1px solid #dee2e6 !important;
  }

  .border-lg-left-2 {
       border-left: 2px solid #dee2e6 !important;
  }

  .border-lg-right-2 {
       border-right: 2px solid #dee2e6 !important;
  }

  .border-lg-bottom-2 {
       border-bottom: 2px solid #dee2e6 !important;
  }

  .border-lg-top-2 {
       border-top: 2px solid #dee2e6 !important;
  }

  .border-lg-left-3 {
       border-left: 3px solid #dee2e6 !important;
  }

  .border-lg-right-3 {
       border-right: 3px solid #dee2e6 !important;
  }

  .border-lg-bottom-3 {
       border-bottom: 3px solid #dee2e6 !important;
  }

  .border-lg-top-3 {
       border-top: 3px solid #dee2e6 !important;
  }
}

@media screen and (min-width:1200px) {
  .border-xl-1 {
       border: 1px solid #dee2e6 !important;
  }

  .border-xl-0 {
       border: 0 !important;
  }

  .border-xl-left-0 {
       border-left: 0 !important;
  }

  .border-xl-right-0 {
       border-right: 0 !important;
  }

  .border-xl-bottom-0 {
       border-bottom: 0 !important;
  }

  .border-xl-top-0 {
       border-top: 0 !important;
  }

  .border-xl-left-1 {
       border-left: 1px solid #dee2e6 !important;
  }

  .border-xl-right-1 {
       border-right: 1px solid #dee2e6 !important;
  }

  .border-xl-bottom-1 {
       border-bottom: 1px solid #dee2e6 !important;
  }

  .border-xl-top-1 {
       border-top: 1px solid #dee2e6 !important;
  }

  .border-xl-left-2 {
       border-left: 2px solid #dee2e6 !important;
  }

  .border-xl-right-2 {
       border-right: 2px solid #dee2e6 !important;
  }

  .border-xl-bottom-2 {
       border-bottom: 2px solid #dee2e6 !important;
  }

  .border-xl-top-2 {
       border-top: 2px solid #dee2e6 !important;
  }

  .border-xl-left-3 {
       border-left: 3px solid #dee2e6 !important;
  }

  .border-xl-right-3 {
       border-right: 3px solid #dee2e6 !important;
  }

  .border-xl-bottom-3 {
       border-bottom: 3px solid #dee2e6 !important;
  }

  .border-xl-top-3 {
       border-top: 3px solid #dee2e6 !important;
  }
}


/* Responsive Ordering */
@media screen and (min-width: 576px) {
  .order-sm-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-sm-last {
    -ms-flex-order: 13;
    order: 13;
  }
}

@media screen and (min-width: 768px) {
  .order-md-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .order-md-last {
    -ms-flex-order: 13;
    order: 13;
  }
}

@media screen and (min-width: 992px) {
  .order-lg-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .order-lg-last {
    -ms-flex-order: 13;
    order: 13;
  }
}

@media screen and (min-width: 1200px) {
  .order-xl-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .order-xl-last {
    -ms-flex-order: 13;
    order: 13;
  }
}

/* Responsive button blocks */
.btn-block-0 {
  display: inline-block;
  width: auto;
}

@media screen and (min-width: 576px) {
  .btn-block-sm-0 {
    display: inline-block;
    width: auto;
  }
}

@media screen and (min-width: 768px) {
  .btn-block-md-0 {
    display: inline-block;
    width: auto;
  }
}

@media screen and (min-width: 992px) {
  .btn-block-lg-0 {
    display: inline-block;
    width: auto;
  }
}

@media screen and (min-width: 1200px) {
  .btn-block-xl-0 {
    display: inline-block;
    width: auto;
  }
}
/* Responsive Custom Margins & Paddings */
.m-1 {
  margin: 8px !important;
}

.mt-1,
.my-1 {
  margin-top: 8px !important;
}

.mr-1,
.mx-1 {
  margin-right: 8px !important;
}

.mb-1,
.my-1 {
  margin-bottom: 8px !important;
}

.ml-1,
.mx-1 {
  margin-left: 8px !important;
}

.m-2 {
  margin: 16px !important;
}

.mt-2,
.my-2 {
  margin-top: 16px !important;
}

.mr-2,
.mx-2 {
  margin-right: 16px !important;
}

.mb-2,
.my-2 {
  margin-bottom: 16px !important;
}

.ml-2,
.mx-2 {
  margin-left: 16px !important;
}

.m-3 {
  margin: 24px !important;
}

.mt-3,
.my-3 {
  margin-top: 24px !important;
}

.mr-3,
.mx-3 {
  margin-right: 24px !important;
}

.mb-3,
.my-3 {
  margin-bottom: 24px !important;
}

.ml-3,
.mx-3 {
  margin-left: 24px !important;
}

.m-4 {
  margin: 32px !important;
}

.mt-4,
.my-4 {
  margin-top: 32px !important;
}

.mr-4,
.mx-4 {
  margin-right: 32px !important;
}

.mb-4,
.my-4 {
  margin-bottom: 32px !important;
}

.ml-4,
.mx-4 {
  margin-left: 32px !important;
}

.m-5 {
  margin: 40px !important;
}

.mt-5,
.my-5 {
  margin-top: 40px !important;
}

.mr-5,
.mx-5 {
  margin-right: 40px !important;
}

.mb-5,
.my-5 {
  margin-bottom: 40px !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-1 {
  padding: 8px !important;
}

.pt-1,
.py-1 {
  padding-top: 8px !important;
}

.pr-1,
.px-1 {
  padding-right: 8px !important;
}

.pb-1,
.py-1 {
  padding-bottom: 8px !important;
}

.pl-1,
.px-1 {
  padding-left: 8px !important;
}

.p-2 {
  padding: 16px !important;
}

.pt-2,
.py-2 {
  padding-top: 16px !important;
}

.pr-2,
.px-2 {
  padding-right: 16px !important;
}

.pb-2,
.py-2 {
  padding-bottom: 16px !important;
}

.pl-2,
.px-2 {
  padding-left: 16px !important;
}

.p-3 {
  padding: 24px !important;
}

.pt-3,
.py-3 {
  padding-top: 24px !important;
}

.pr-3,
.px-3 {
  padding-right: 24px !important;
}

.pb-3,
.py-3 {
  padding-bottom: 24px !important;
}

.pl-3,
.px-3 {
  padding-left: 24px !important;
}

.p-4 {
  padding: 32px !important;
}

.pt-4,
.py-4 {
  padding-top: 32px !important;
}

.pr-4,
.px-4 {
  padding-right: 32px !important;
}

.pb-4,
.py-4 {
  padding-bottom: 32px !important;
}

.pl-4,
.px-4 {
  padding-left: 32px !important;
}

.p-5 {
  padding: 40px !important;
}

.pt-5,
.py-5 {
  padding-top: 40px !important;
}

.pr-5,
.px-5 {
  padding-right: 40px !important;
}

.pb-5,
.py-5 {
  padding-bottom: 40px !important;
}

.pl-5,
.px-5 {
  padding-left: 40px !important;
}

.p-6 {
  padding: 40px !important;
}

.pt-6,
.py-6 {
  padding-top: 48px !important;
}

.pr-6,
.px-6 {
  padding-right: 48px !important;
}

.pb-6,
.py-6 {
  padding-bottom: 48px !important;
}

.pl-6,
.px-6 {
  padding-left: 48px !important;
}

.p-7 {
  padding: 56px !important;
}

.pt-7,
.py-7 {
  padding-top: 56px !important;
}

.pr-7,
.px-7 {
  padding-right: 56px !important;
}

.pb-7,
.py-7 {
  padding-bottom: 56px !important;
}

.pl-7,
.px-7 {
  padding-left: 56px !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 8px !important;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 8px !important;
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 8px !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 8px !important;
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 8px !important;
  }

  .m-sm-2 {
    margin: 16px !important;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 16px !important;
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 16px !important;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 16px !important;
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 16px !important;
  }

  .m-sm-3 {
    margin: 24px !important;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 24px !important;
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 24px !important;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 24px !important;
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 24px !important;
  }

  .m-sm-4 {
    margin: 32px !important;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 32px !important;
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 32px !important;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 32px !important;
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 32px !important;
  }

  .m-sm-5 {
    margin: 40px !important;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 40px !important;
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 40px !important;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 40px !important;
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 40px !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 8px !important;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 8px !important;
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 8px !important;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 8px !important;
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 8px !important;
  }

  .p-sm-2 {
    padding: 16px !important;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 16px !important;
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 16px !important;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 16px !important;
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 16px !important;
  }

  .p-sm-3 {
    padding: 24px !important;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 24px !important;
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 24px !important;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 24px !important;
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 24px !important;
  }

  .p-sm-4 {
    padding: 32px !important;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 32px !important;
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 32px !important;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 32px !important;
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 32px !important;
  }

  .p-sm-5 {
    padding: 40px !important;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 40px !important;
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 40px !important;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 40px !important;
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 40px !important;
  }

  .p-sm-6 {
    padding: 48px !important;
  }

  .pt-sm-6,
  .py-sm-6 {
    padding-top: 48px !important;
  }

  .pr-sm-6,
  .px-sm-6 {
    padding-right: 48px !important;
  }

  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 48px !important;
  }

  .pl-sm-6,
  .px-sm-6 {
    padding-left: 48px !important;
  }

  .p-sm-7 {
    padding: 56px !important;
  }

  .pt-sm-7,
  .py-sm-7 {
    padding-top: 56px !important;
  }

  .pr-sm-7,
  .px-sm-7 {
    padding-right: 56px !important;
  }

  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 56px !important;
  }

  .pl-sm-7,
  .px-sm-7 {
    padding-left: 56px !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 8px !important;
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: 8px !important;
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: 8px !important;
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 8px !important;
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: 8px !important;
  }

  .m-md-2 {
    margin: 16px !important;
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: 16px !important;
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: 16px !important;
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 16px !important;
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: 16px !important;
  }

  .m-md-3 {
    margin: 24px !important;
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 24px !important;
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 24px !important;
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 24px !important;
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 24px !important;
  }

  .m-md-4 {
    margin: 32px !important;
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 32px !important;
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 32px !important;
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 32px !important;
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 32px !important;
  }

  .m-md-5 {
    margin: 40px !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 40px !important;
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 40px !important;
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 40px !important;
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 40px !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 8px !important;
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: 8px !important;
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: 8px !important;
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 8px !important;
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: 8px !important;
  }

  .p-md-2 {
    padding: 16px !important;
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: 16px !important;
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: 16px !important;
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 16px !important;
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: 16px !important;
  }

  .p-md-3 {
    padding: 24px !important;
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 24px !important;
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 24px !important;
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 24px !important;
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 24px !important;
  }

  .p-md-4 {
    padding: 32px !important;
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 32px !important;
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 32px !important;
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 32px !important;
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 32px !important;
  }

  .p-md-5 {
    padding: 40px !important;
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 40px !important;
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 40px !important;
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 40px !important;
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 40px !important;
  }

  .p-md-6 {
    padding: 48px !important;
  }

  .pt-md-6,
  .py-md-6 {
    padding-top: 48px !important;
  }

  .pr-md-6,
  .px-md-6 {
    padding-right: 48px !important;
  }

  .pb-md-6,
  .py-md-6 {
    padding-bottom: 48px !important;
  }

  .pl-md-6,
  .px-md-6 {
    padding-left: 48px !important;
  }

  .p-md-7 {
    padding: 56px !important;
  }

  .pt-md-7,
  .py-md-7 {
    padding-top: 56px !important;
  }

  .pr-md-7,
  .px-md-7 {
    padding-right: 56px !important;
  }

  .pb-md-7,
  .py-md-7 {
    padding-bottom: 56px !important;
  }

  .pl-md-7,
  .px-md-7 {
    padding-left: 56px !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 8px !important;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 8px !important;
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 8px !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 8px !important;
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 8px !important;
  }

  .m-lg-2 {
    margin: 16px !important;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 16px !important;
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 16px !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 16px !important;
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 16px !important;
  }

  .m-lg-3 {
    margin: 24px !important;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 24px !important;
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 24px !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 24px !important;
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 24px !important;
  }

  .m-lg-4 {
    margin: 32px !important;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 32px !important;
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 32px !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 32px !important;
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 32px !important;
  }

  .m-lg-5 {
    margin: 40px !important;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 40px !important;
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 40px !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 40px !important;
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 40px !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 8px !important;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 8px !important;
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 8px !important;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 8px !important;
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 8px !important;
  }

  .p-lg-2 {
    padding: 16px !important;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 16px !important;
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 16px !important;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 16px !important;
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 16px !important;
  }

  .p-lg-3 {
    padding: 24px !important;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 24px !important;
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 24px !important;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 24px !important;
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 24px !important;
  }

  .p-lg-4 {
    padding: 32px !important;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 32px !important;
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 32px !important;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 32px !important;
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 32px !important;
  }

  .p-lg-5 {
    padding: 40px !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 40px !important;
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 40px !important;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 40px !important;
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 40px !important;
  }

  .p-lg-6 {
    padding: 48px !important;
  }

  .pt-lg-6,
  .py-lg-6 {
    padding-top: 48px !important;
  }

  .pr-lg-6,
  .px-lg-6 {
    padding-right: 48px !important;
  }

  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 48px !important;
  }

  .pl-lg-6,
  .px-lg-6 {
    padding-left: 48px !important;
  }

  .p-lg-7 {
    padding: 56px !important;
  }

  .pt-lg-7,
  .py-lg-7 {
    padding-top: 56px !important;
  }

  .pr-lg-7,
  .px-lg-7 {
    padding-right: 56px !important;
  }

  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 56px !important;
  }

  .pl-lg-7,
  .px-lg-7 {
    padding-left: 56px !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 8px !important;
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 8px !important;
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 8px !important;
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 8px !important;
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 8px !important;
  }

  .m-xl-2 {
    margin: 16px !important;
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 16px !important;
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 16px !important;
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 16px !important;
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 16px !important;
  }

  .m-xl-3 {
    margin: 24px !important;
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 24px !important;
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 24px !important;
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 24px !important;
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 24px !important;
  }

  .m-xl-4 {
    margin: 32px !important;
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 32px !important;
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 32px !important;
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 32px !important;
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 32px !important;
  }

  .m-xl-5 {
    margin: 40px !important;
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 40px !important;
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 40px !important;
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 40px !important;
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 40px !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 8px !important;
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 8px !important;
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: 8px !important;
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 8px !important;
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: 8px !important;
  }

  .p-xl-2 {
    padding: 16px !important;
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 16px !important;
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: 16px !important;
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 16px !important;
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: 16px !important;
  }

  .p-xl-3 {
    padding: 24px !important;
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 24px !important;
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 24px !important;
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 24px !important;
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 24px !important;
  }

  .p-xl-4 {
    padding: 32px !important;
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 32px !important;
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 32px !important;
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 32px !important;
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 32px !important;
  }

  .p-xl-5 {
    padding: 40px !important;
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 40px !important;
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 40px !important;
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 40px !important;
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 40px !important;
  }

  .p-xl-6 {
    padding: 48px !important;
  }

  .pt-xl-6,
  .py-xl-6 {
    padding-top: 48px !important;
  }

  .pr-xl-6,
  .px-xl-6 {
    padding-right: 48px !important;
  }

  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 48px !important;
  }

  .pl-xl-6,
  .px-xl-6 {
    padding-left: 48px !important;
  }

  .p-xl-7 {
    padding: 56px !important;
  }

  .pt-xl-7,
  .py-xl-7 {
    padding-top: 56px !important;
  }

  .pr-xl-7,
  .px-xl-7 {
    padding-right: 56px !important;
  }

  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 56px !important;
  }

  .pl-xl-7,
  .px-xl-7 {
    padding-left: 56px !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

/* Mockup Automcomplete for Textbox */
.dce-autocomplete {
  position: relative;
}

.dce-autocomplete-items {
  position: absolute;
  border: 1px solid #5a469b;
  z-index: 899;
  border-bottom: 0;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
}

.dce-autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #5a469b;
}

.dce-autocomplete .autocomplete-items div:hover {
  background-color: #e9e9e9;
}

.dce-autocomplete .autocomplete-active {
  background-color: #787070 !important;
  color: #ffffff;
}

/* Disclosure Footnote box*/
.dce-card-disclosure {
  padding: 16px;
  border: 0;
  -webkit-box-shadow: inset 0px 0px 0px 1px #3b3331;
  -moz-box-shadow: inset 0px 0px 0px 1px #3b3331;
  box-shadow: inset 0px 0px 0px 1px #3b3331;
  background-color: #fff;
  border-radius: 0;
  color: #3b3331;
  font-size: 1.071rem;
}

.dce-disclosure-content {
  font-size: 0.929rem;
}


/* Collapse setup*/
.dce-collapse-link {
  font-size: 1.071rem;
  color: #3b3331 !important;
  text-decoration: none;
}

.dce-collapse-link:hover,
.dce-collapse-link:active {
  text-decoration: underline !important;
}

.dce-collapse-link .fa {
  padding-right: 1em;
  font-size: 0.9rem;
}

/* Alert Messages */
.dce-msg-container {
  border-radius: 2px !important;
  border: solid 2px #9c9191 !important;
  font-size: 1rem !important;
  padding: 0 !important;
  margin: 0 !important;
  color: #3b3331 !important;
}

.dce-msg-container .dce-msg-icon {
  padding: 12px;
}

.dce-msg-container .dce-msg-icon .fa {
  font-size: 40px;
}

.dce-msg-container .dce-msg-icon .fa.msg-alert,
.dce-msg-container .dce-msg-icon .fa.msg-urgent {
  color: #d73f26;
}

.dce-msg-container .dce-msg-icon .fa.msg-confirm {
  color: #178757;
}

.dce-msg-container .dce-msg-icon .fa.msg-error {
  color: #bf2d19;
}

.dce-msg-container .dce-msg-icon .fa.msg-info,
.dce-msg-container .dce-msg-icon .fa.msg-disaster {
  color: #5a469b;
}

.dce-msg-container .dce-msg-text {
  padding: 16px 16px 16px 0;
}

/* Page Content Backdrop */
.dce-bgpage {
  background-color: #ffffff;
  background-position: left top;
  background-repeat: repeat-x;
  background-image: none;
}

.dce-bgpage-photo1-xs {
  background-image: url(./global/img/bg-gradient_231x1.png);
}

@media screen and (min-width: 576px) {
  .dce-bgpage-photo1-sm {
    background-image: url(./global/img/bg-gradient_231x1.png);
  }
}

@media screen and (min-width: 768px) {
  .dce-bgpage-photo1-md {
    background-image: url(./global/img/bg-gradient_231x1.png);
  }
}

@media screen and (min-width: 992px) {
  .dce-bgpage-photo1-lg {
    background-image: url(./global/img/bg-gradient_231x1.png);
  }
}

@media screen and (min-width: 1200px) {
  .dce-bgpage-photo1-xl {
    background-image: url(./global/img/bg-gradient_231x1.png);
  }
}

/* help classes for Multselect */
.multi-select .dropdown-heading {
  height: 24px;
  padding: 0 15px;
  margin: 4px 0;
}

.multi-select .clear-selected-button {
  height: auto;
  margin: 4px;
}

/*helper classes - must be last */
.bg-white {
  background-color: #fff;
}

.bg-pale {
  background-color: #f9f7f6;
}

.bg-gray {
  background-color: #e0e0e0;
}

.bg-dgray {
  background-color: #f4f0ed;
}

.bg-dgray-alt {
  background-color: #44464a;
}

.bg-red {
  background-color: #d71e28;
}

.bg-wmg {
  background-color: #a27f48;
}

.MuiIconButton-edgeEnd {
  margin-top: 30px !important;
  margin-right: -8px !important;
}

.Mui-focusVisible {
  background-color: transparent !important;
  outline: 1px solid #5e9ed6 !important;
}

.MuiTouchRipple-child {
  background-color: transparent !important;
}

/*Edge Password eye icon hide */
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.btn,
button {
  font-family: "Wells Fargo Sans SemiBold", Verdana, Geneva, sans-serif !important;
  font-size: 16px;
  line-height: 20px;
  border-radius: 8px;
  border-width: 2px;
  padding: 9px 30px 9px 30px;
  margin: 8px 16px;
  height: 40px;
  outline: 0 !important;
  box-shadow: none !important;
}

/* Display - XS,SM,MD only */
@media screen and (max-width: 767px) {
  .eca-container > .col-12,
  .eca-container > .card > .card-body {
    padding: 0;
  }
}

a.balloon-help,
a.balloon-help:link,
a.balloon-help:focus {
     text-decoration: underline dashed #3B3331;
     -webkit-text-decoration: underline dashed #3B3331;
     font-size: 0.9rem;
     color: #3B3331;
     margin: 8px 8px 0px 8px;
}

.pl-1, .px-1 {
  padding-left: 8px !important;
}

.m-0 {
  margin: 0 ;
}

.popover {
  color: #3B3331;
  font-size: 1rem;
  border: 1px solid #E2DEDE;
  border-radius: 2px;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(20,20,20,0.15);
  -moz-box-shadow: 0px 2px 4px 0px rgba(20,20,20,0.15);
  box-shadow: 0px 2px 4px 0px rgba(20,20,20,0.15);
  padding: 16px;
  /*background-image: url('../img/icon-close.png');*/
  background-position: top right;
  background-repeat: no-repeat;
  background-origin:content-box;
}

.popover-header {
  padding: 0;
  margin: 0 0 12px 0;
  font-size: 1rem;
  font-family: "Wells Fargo Sans SemiBold",Verdana,Geneva,sans-serif;
  color: inherit;
  border: 0;
  background-color: transparent;
  border-radius: 0;
  /*padding-right: 40px;*/
  cursor: pointer;
}

.popover-body {
  padding: 0;
  color: inherit;
}

.title-close,
.title-close:hover,
.title-close:focus {
  height: auto !important;
  color: #000;
  text-decoration: none;
  font-size:32px;
  font-weight: normal;
  float: right;
}

.text-nounderline {
  text-decoration: none !important;
}

.btn-sm-p-0 {
  padding: 0!important;
}

.btn-sm-m-0 {
  margin: 0!important;
}

.ReactModal__Overlay{
    background-color: rgba(.5,.5,.5,.5) !important;
    left: 0 !important;
    position: fixed !important;
    top: 0 !important;
    width: 100vw !important;
    z-index: 1040 !important;
}
.ReactModal__Overlay--after-open{
 
}

.ReactModal__Content--after-open{
backdrop-filter: none !important;
-webkit-backdrop-filter: none !important;
}

.ReactModal__Content ReactModal__Content--after-open:focus {
	border: none !important;
	background:none !important;
	border-radius: 0px !important;
}

.ReactModal__Body--open{
overflow-x: hidden;
    overflow-y: auto;
}

.skip-to-main-content-link {
  position: absolute;
  left: -9999px;
  z-index: 999;
  padding: 1em;
  background-color: black;
  color: white;
  opacity: 0;
}
.skip-to-main-content-link:focus {
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
}
