 /* Liberty styles only */


.liberty.container-masthead .logo {
  margin-top: 1px;
}
.liberty.container-masthead .logo img { 
  margin: 3px 0;
  max-height: 50px;
  padding-top: 1px;
}
.liberty.container-masthead-mobile .logo img {
  margin: 6px 0;
  max-height: 40px;
  padding-top: 1px;
}

